import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import React from 'react';
import { connect } from 'react-redux';
import { Gantt, GanttConfigOptions, GanttStatic } from '@dhx/trial-gantt';
import '@dhx/trial-gantt/codebase/dhtmlxgantt.css';
import './styles.scss';
import { httpGet } from '@core/http/requests';
import { getHostName } from '@core/http/helpers';

interface Props {
  record: DbRecordEntityTransform;
  config?: GanttConfigOptions;
  dataProcessorUrl?: string | undefined;
}

interface State {
  ganttInstance: GanttStatic | undefined;
}

class GanttChart extends React.Component<Props, State> {
  private ganttRef = React.createRef<HTMLDivElement>();

  constructor(props: Props) {
    super(props);
    this.state = {
      ganttInstance: undefined
    }
  }

  componentDidMount() {
    if (this.ganttRef.current) {
      const gantt = Gantt.getGanttInstance();

      this.configureGantt(gantt);

      gantt.init(this.ganttRef.current);
      this.setState({ ganttInstance: gantt });
    }
  }

  componentWillUnmount() {
    if (this.state.ganttInstance) {
      this.state.ganttInstance.destructor();
    }
  }

  private configureGantt(gantt: GanttStatic) {
    gantt.config.drag_progress = false;
    gantt.plugins({ marker: true });

    // Set XML date format to handle ISO dates correctly. Avoids us having to convert the dates in the backend.
    gantt.config.xml_date = "%Y-%m-%d %H:%i";

    // Add today red line marker
    gantt.addMarker({
      start_date: new Date(),
      css: 'today-line',
      title: new Date().toDateString(),
    });

    // Add weekend background color
    gantt.templates.timeline_cell_class = function (task, date) {
      return date.getDay() === 0 || date.getDay() === 6 ? 'weekend' : '';
    };

    gantt.config.scale_unit = 'month';
    gantt.config.date_scale = '%F, %Y';
    gantt.config.scale_height = 50;
    gantt.config.subscales = [
      { unit: 'day', step: 1, date: '%j, %D' }
    ];

    // Override config if provided
    if (this.props.config) {
      Object.assign(gantt.config, this.props.config);
    }

    if (this.props.dataProcessorUrl) {
      const token = localStorage.getItem(`token`);

      const dp = gantt.createDataProcessor({
        url: `${getHostName()}/${this.props.dataProcessorUrl}`,
        mode: 'REST-JSON',
        deleteAfterConfirmation: true,
      });

      httpGet(this.props.dataProcessorUrl).then((response) => {
        gantt.parse(response.data.data);
      });

      dp.setTransactionMode({
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }, false);
    }
  }

  render() {
    return (
      <div
        ref={this.ganttRef}
        style={{
          width: '100%',
          // Subtract height of header, navigation and padding (190px) to fill remaining viewport
          height: 'calc(100vh - 190px)'
        }}
      />
    );
  }
}

const mapState = (state: any) => ({});
const mapDispatch = (dispatch: any) => ({});

export default connect(mapState, mapDispatch)(GanttChart);
