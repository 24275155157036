import {
  Callout,
  DialogStep,
  IconName,
  MultistepDialog,
  NonIdealState,
  Section,
  SectionCard,
} from '@blueprintjs/core';
import { DbRecordCreateUpdateDto } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/dto/db.record.create.update.dto';
import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { PipelineEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/pipeline/pipeline.entity';
import { PipelineStageEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/pipeline/stage/pipeline.stage.entity';
import { SchemaActionEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/action/schema.action.entity';
import { SchemaEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/schema.entity';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { closeRecordForm } from '@legacy/core/records/components/Forms/store/actions';
import { updateRecordInShortList } from '@legacy/core/records/store/actions';
import { httpDelete, httpGet, httpPost, httpPut } from '../../../http/requests';
import { hasPermissions } from '../../../helpers/rbacRules';
import { displayMessage } from '@legacy/core/messages/store/reducers';
import { getOdinSchemaByRecord } from '../../../helpers/schemaHelpers';
import { getSchemaActionsForStageChangeFlow } from '../schemaActionFilters';
import SchemaActionFlowStep from '../SchemaActionFlowStep';

interface PropsType {
  record: DbRecordEntityTransform;
  targetStage: string | undefined;
  recordFormReducer: any;
  openDialog?: boolean;
  onClose?: any;
  onConfirm?: any;
  closeForm: () => void;
  alertMessage: (params: { body: string; type: string }) => void;
  updateRecordInReducer: (params: { record: DbRecordEntityTransform }) => void;
  userReducer: any;
}

const RecordStageChangeDialog: FunctionComponent<PropsType> = (props) => {
  const {
    record,
    targetStage,
    recordFormReducer,
    updateRecordInReducer,
    openDialog,
    onClose,
    onConfirm,
    alertMessage,
    closeForm,
    userReducer,
  } = props;

  const [isDialogOpen, setIsDialogOpen] = React.useState<boolean>(false);
  const [isNextDisabled, setIsNextDisabled] = React.useState<boolean>(true);
  const [isConfirmLoading, setIsConfirmLoading] = React.useState<boolean>(false);
  const [flowFormData, setFlowFormData] = useState<any[]>([]);
  const [isLoadingSchemaAction, setIsLoadingSchemaAction] = useState<boolean>(false);
  const [recordSchema, setRecordSchema] = React.useState<SchemaEntity | undefined>(undefined);
  const [pipeline, setPipeline] = useState<PipelineEntity | undefined>(undefined);
  const [updateResults, setUpdateResults] = useState<any[]>([]);
  const [createResults, setCreateResults] = useState<any[]>([]);
  const [schemaActionFlow, setSchemaActionFlow] = React.useState<SchemaActionEntity | undefined>(
    undefined,
  );
  const [error, setError] = useState<TError | undefined>();

  type TError = {
    title: string;
    message: string;
    icon?: IconName;
  };

  function resetState() {
    setIsNextDisabled(true);
    setIsConfirmLoading(false);
    setCreateResults([]);
    setUpdateResults([]);
  }

  const closeDialog = () => {
    onClose && onClose();
    resetState();
    setIsDialogOpen(false);
    setFlowFormData([]);
    setSchemaActionFlow(undefined);
    setCreateResults([]);
    setUpdateResults([]);
  };

  useEffect(() => {
    if (openDialog && record) {
      setIsDialogOpen(openDialog);
      closeForm();
      resetState();
      fetchSchema();
    }
  }, [openDialog, record]);

  const fetchSchema = async () => {
    const schema = await getOdinSchemaByRecord(record);
    setRecordSchema(schema);
  };

  useEffect(() => {
    if (recordSchema && targetStage && openDialog) {
      fetchSchemaActionFlows();
      fetchPipeline();
    }
  }, [recordSchema, openDialog]);

  const fetchPipeline = () => {
    if (recordSchema) {
      httpGet(
        `SchemaModule/v1.0/pipelines/bymodule/${recordSchema.moduleName}/${
          recordSchema.entityName
        }${record.type ? `?schemaType=${record.type}` : '?schemaType='}`,
      ).then((res: any) => {
        const pipelines = res.data?.data || [];
        if (pipelines.length > 0) {
          setPipeline(pipelines[0]);
        }
      });
    }
  };

  const flowHasDialogSteps = (flow: any): boolean => {
    console.log('🛠️ debug: FLOW', flow);
    return flow && flow?.definition?.dialogSteps?.length! > 0;
  };

  // Fetch all schema actions and apply a rule set
  function fetchSchemaActionFlows() {
    if (record) {
      setError(undefined);
      setIsLoadingSchemaAction(true);
      setSchemaActionFlow(undefined);
      httpGet(`SchemaModule/v1.0/schemas-actions/schema/${record?.schemaId}`)
        .then((res) => {
          setIsLoadingSchemaAction(false);

          let filteredSchemaActionFlows: SchemaActionEntity[] = getSchemaActionsForStageChangeFlow(
            res.data?.data,
            record,
            recordSchema!,
            targetStage!,
          );

          // Flows found and one of them has dialog steps, show a multistep dialog.
          if (
            filteredSchemaActionFlows.length > 0 &&
            flowHasDialogSteps(filteredSchemaActionFlows[0])
          ) {
            const permissions: string[] =
              filteredSchemaActionFlows[0]?.permissions?.map((perm: any) => perm.name) || [];

            if (permissions.length > 0 && !hasPermissions(userReducer, permissions)) {
              setError({
                title: 'Permission Denied',
                message:
                  'You do not have the required permissions to perform this action. Please contact your administrator',
                icon: 'lock',
              });
            } else {
              console.log(
                '%cdebug: [RecordStageChangeDialog] Flow found.',
                'color:limegreen',
                filteredSchemaActionFlows,
              );
              setSchemaActionFlow(filteredSchemaActionFlows[0]);
            }
          }

          // More than one flows found but first one has no dialog steps. This will allow the user
          // to update the record stage without any steps.
          else if (filteredSchemaActionFlows.length > 0) {
            console.log(
              '%cdebug: [RecordStageChangeDialog] Flow found, without dialog steps.',
              'color:#35fce5',
              filteredSchemaActionFlows,
            );
          }

          // No Flows found - display the error message.
          else {
            console.log('%cdebug: [RecordStageChangeDialog] No flow found.', 'color:salmon');
            setError({
              title: 'No Flows Found',
              message:
                'There is no Flow action found for this stage update. Please create one, or contact your administrator.',
              icon: 'search',
            });
          }
        })
        .catch((err) => {
          console.error('Error loading table data:', err);
        });
    }
  }

  function setupStep(newStep: any) {
    if (newStep === 0) {
      setFlowFormData([]);
      closeForm();
    } else if (newStep > 0 && newStep <= schemaActionFlow?.definition?.dialogSteps?.length) {
      let newFlowFormData: any[] = flowFormData.slice(0, Number(newStep) - 1);
      newFlowFormData.push(recordFormReducer);
      setFlowFormData(newFlowFormData);
      closeForm();
    }
  }

  // Debug
  // useEffect(() => {
  //   console.log('%cdebug: flowData updated', 'color:royalblue', flowFormData);
  // }, [flowFormData]);

  // Some schema action flows can pass custom URLs to be executed after the Flow is completed. We pass the creates and updates to the custom URL endpoint.
  const handleCustomURLs = async (creates: any[], updates: any[]) => {
    const onSubmitUrl = schemaActionFlow?.definition?.onSubmitUrl;

    if (schemaActionFlow && onSubmitUrl) {
      try {
        let URL = onSubmitUrl.url;

        // Replace source record id if asked in schema configuration
        if (URL && URL.includes('{source_record_id}')) {
          URL = URL.replace('{source_record_id}', record.id);
        }

        // Support POST, PUT, DELETE methods but fallback to POST
        if (onSubmitUrl.method === 'post') {
          return await httpPost(URL, {
            creates: creates,
            updates: updates,
          })
            .then((res: any) => {
              if (res.data?.data?.length! > 0) {
                setCreateResults(res.data.data);
              }
            })
            .catch((err: any) => {
              alertMessage({
                body: 'Could not execute custom URL endpoint. ' + err.message,
                type: 'error',
              });
              closeDialog();
              return true;
            });
        } else if (onSubmitUrl.method === 'put') {
          return await httpPut(URL, {
            creates: creates,
            updates: updates,
          });
        } else if (onSubmitUrl.method === 'delete') {
          return await httpDelete(URL);
        } else {
          return await httpPost(URL, {
            creates: creates,
            updates: updates,
          });
        }
      } catch (err: any) {
        alertMessage({
          body: 'Could not execute custom URL endpoint',
          type: 'error',
        });
        return true;
      }
    }
  };

  async function handleFinalStepSubmit() {
    // This means we are in the summary step, so we can close the dialog here.
    if (createResults.length > 0 || updateResults.length > 0) {
      closeDialog();
      return true;
    }

    setIsConfirmLoading(true);

    try {
      const onSubmitUrl = schemaActionFlow?.definition?.onSubmitUrl;
      const shouldSkipStageUpdate: boolean =
        schemaActionFlow?.definition?.settings?.skipStageUpdate;
      const shouldShowSummaryStep: boolean =
        schemaActionFlow?.definition?.settings?.showSummaryStep;
      let createPayload: DbRecordCreateUpdateDto[] = [];
      let updatePayload: DbRecordCreateUpdateDto[] = [];

      let updateSteps = flowFormData.filter(
        (formReducerSnapshot: any) =>
          formReducerSnapshot.schema?.id === record.schemaId && formReducerSnapshot.isUpdateReq,
      );
      let createSteps = flowFormData.filter(
        (formReducerSnapshot: any) => formReducerSnapshot.isCreateReq,
      );

      // 1. UPDATE STEPS
      if (!shouldSkipStageUpdate) {
        updatePayload.push({
          id: record.id,
          entity: record.entity,
          type: record.type,
          stageKey: targetStage,
        });
      }
      if (updateSteps.length > 0) {
        updateSteps.map((formReducerSnapshot: any) => {
          updatePayload.push({
            id: record.id,
            entity: record.entity,
            type: record.type,
            properties: formReducerSnapshot.modified[0].properties,
          });
        });
        console.log('debug: Adding update steps to payload', updatePayload);
      }

      // 1.1 If there are many update steps with same id in the upload DTO list - merge them. This is to avoid multiple updates to the same record. Use Map collection to maintain unique keys, apply efficient lookup and clean merging logic.
      let uniqueUploadPayloadMap = new Map();
      updatePayload.forEach((DTO: DbRecordCreateUpdateDto) => {
        if (uniqueUploadPayloadMap.has(DTO.id)) {
          let existingUpdate = uniqueUploadPayloadMap.get(DTO.id);
          existingUpdate.properties = {
            ...existingUpdate.properties,
            ...DTO.properties,
          };
          if (!existingUpdate.type && DTO.type) {
            existingUpdate.type = DTO.type;
          }
          if (!existingUpdate.stageKey && DTO.stageKey) {
            existingUpdate.stageKey = DTO.stageKey;
          }
          uniqueUploadPayloadMap.set(DTO.id, existingUpdate);
        } else {
          uniqueUploadPayloadMap.set(DTO.id, DTO);
        }
      });

      // 2. CREATE STEPS
      if (createSteps.length > 0) {
        createPayload = createSteps.map((formReducerSnapshot: any, i: number) => {
          return {
            entity: `${formReducerSnapshot.schema?.moduleName}:${formReducerSnapshot.schema?.entityName}`,
            type: formReducerSnapshot?.recordType,
            properties: formReducerSnapshot.modified[0]?.properties,
            schemaId: formReducerSnapshot.schema?.id,
            schemaActionId: formReducerSnapshot.schemaActionId,
            associations: [
              ...formReducerSnapshot.modified[0]?.associations,
              {
                entity: record?.entity,
                recordId: record?.id,
                linkType: formReducerSnapshot?.custom?.linkType,
                relationType: formReducerSnapshot.schema?.id === record?.schemaId ? 'PARENT' : null,
              },
            ],
          };
        });
      }

      // 1. REGULAR API REQUEST
      //
      if (!onSubmitUrl) {
        const payload = Array.from(uniqueUploadPayloadMap.values());

        // a) Update Payloads
        if (payload.length > 0) {
          console.log('debug: Updating records with update payload', payload);

          await httpPut(`${recordSchema?.moduleName}/v1.0/db/bulk-update`, {
            recordsToUpdate: payload,
          }).then((res: any) => {
            if (res.data?.data?.length! > 0) {
              setUpdateResults(res.data.data);
            }
          });
          const updatedRecord = await httpGet(
            `${recordSchema?.moduleName}/v1.0/db/${recordSchema?.entityName}/${record.id}`,
          );
          updateRecordInReducer({ record: updatedRecord.data.data });
        }

        // b) Create Payloads
        if (createPayload.length > 0) {
          console.log('debug: Creating records with create payload', createPayload);

          await httpPost(`${recordSchema?.moduleName}/v1.0/db/bulk-upsert`, {
            recordsToUpsert: createPayload,
            options: {
              linkRelatedRecordsAfterUpsert: true,
            },
          }).then((res: any) => {
            if (res.data?.data?.creates?.length! > 0) {
              setCreateResults(res.data.data.creates);
            }
            if (res.data?.data?.updates?.length! > 0) {
              setUpdateResults(res.data.data.updates);
            }
          });
        }

        // c) If we're not showing a summary step, close the dialog
        if (!shouldShowSummaryStep) {
          closeDialog();
        }

        // d) If no schema action Flow, close the modal
        if (!schemaActionFlow) {
          closeDialog();
        }

        // e) Show success message
        alertMessage({
          body: 'Record stage updated successfully',
          type: 'success',
        });

        // f) If there's a callback, run it
        if (onConfirm) {
          onConfirm();
        }

        setIsConfirmLoading(false);
      }

      // 2. CUSTOM URL REQUEST
      //
      else {
        console.log('debug: Sending payload to a custom URL endpoint');
        await handleCustomURLs(createPayload, updatePayload);
      }
    } catch (err: any) {
      setIsConfirmLoading(false);
      console.log('debug: error', err);
      alertMessage({
        body: err?.response?.data?.message,
        type: 'error',
      });
    }
  }

  // Render each step as a DialogStep
  const renderSchemaActionSteps = () => {
    const steps = schemaActionFlow?.definition?.dialogSteps || [];

    if (steps.length > 0) {
      return steps.map((step: any, index: number) => {
        return (
          <DialogStep
            title={step.name}
            id={index}
            key={index}
            panel={
              <Section style={{ overflowY: 'auto' }}>
                <SectionCard>
                  <SchemaActionFlowStep
                    step={step}
                    sourceRecord={record}
                    isNextDisabled={(isNextDisabled: boolean) => {
                      setIsNextDisabled(isNextDisabled);
                    }}
                    key={index}
                  />
                </SectionCard>
              </Section>
            }
          />
        );
      });
    } else {
      return <Callout intent="danger">No schema action flow steps defined.</Callout>;
    }
  };

  const getPipelineNameByKey = (key: string | undefined): string => {
    if (pipeline && key) {
      const pipelineStage = pipeline?.stages?.find(
        (stage: PipelineStageEntity) => stage.key === key,
      );
      return pipelineStage ? pipelineStage.name : '';
    } else {
      return '';
    }
  };

  const getConfirmationCalloutIcon = () => {
    if (pipeline && targetStage) {
      const stage = pipeline.stages?.find(
        (stage: PipelineStageEntity) => stage.key === targetStage,
      );
      if (stage?.isSuccess) {
        return 'tick-circle';
      } else if (stage?.isFail) {
        return 'warning-sign';
      } else return 'info-sign';
    } else {
      return null;
    }
  };

  const getConfirmationCalloutIntent = () => {
    if (pipeline && targetStage) {
      const stage = pipeline.stages?.find(
        (stage: PipelineStageEntity) => stage.key === targetStage,
      );
      if (stage?.isSuccess) {
        return 'success';
      } else if (stage?.isFail) {
        return 'danger';
      } else return 'primary';
    } else {
      return 'none';
    }
  };

  return (
    <>
      <MultistepDialog
        title={
          !createResults.length && !updateResults
            ? `Updating Stage (${getPipelineNameByKey(record.stage?.key)} → ${getPipelineNameByKey(
                targetStage,
              )})`
            : 'Stage Update'
        }
        isOpen={isDialogOpen}
        style={{
          minWidth: isMobile ? 'auto' : 800,
          width: isMobile ? '100%' : '50%',
        }}
        canOutsideClickClose={false}
        showCloseButtonInFooter={true}
        icon="info-sign"
        navigationPosition="top"
        initialStepIndex={0}
        backButtonProps={{
          disabled: createResults.length > 0 || updateResults.length > 0,
        }}
        onClose={closeDialog}
        usePortal={true}
        onChange={(newStep: string) => {
          setupStep(newStep);
          setIsNextDisabled(true);
        }}
        nextButtonProps={{
          disabled: isNextDisabled,
        }}
        finalButtonProps={{
          disabled: isConfirmLoading,
          onClick: () => handleFinalStepSubmit(),
          text: 'Finish',
        }}
        lazy
      >
        {/* Render dynamic schema action steps */}
        {renderSchemaActionSteps()}

        {/* Error ? */}
        {error && (
          <DialogStep
            title="Error"
            id="error"
            key="error"
            panel={
              <div style={{ padding: 30 }}>
                <NonIdealState
                  icon={error?.icon || 'error'}
                  title={error?.title || 'Error'}
                  description={error?.message || 'An error occurred'}
                />
              </div>
            }
          />
        )}

        {/* Confirmation step */}
        <DialogStep
          title="Confirmation"
          id={schemaActionFlow?.definition?.dialogSteps?.length}
          key="confirmation"
          panel={
            <Section style={{ overflowY: 'auto' }}>
              <SectionCard>
                {/* Show Confirmation */}
                {!createResults.length && !updateResults.length && (
                  <Callout
                    icon={getConfirmationCalloutIcon()}
                    title="Confirmation Step"
                    intent={getConfirmationCalloutIntent()}
                  >
                    <span>
                      {schemaActionFlow?.definition?.settings?.skipStageUpdate ? (
                        <span>Please confirm that you want to finish the update process.</span>
                      ) : (
                        <>
                          <br />
                          <span>
                            Please confirm that you want to move the record to{' '}
                            <strong>{getPipelineNameByKey(targetStage)}</strong> stage.
                          </span>
                        </>
                      )}
                    </span>
                  </Callout>
                )}

                {/* Create Results */}
                {createResults.length > 0 && (
                  <Callout intent="success" icon={null} title="Created Records">
                    <ul>
                      {createResults?.map((result: any, index: number) => {
                        return (
                          <li key={index}>
                            <Link
                              to={`/${result.entity.split(':')[0]}/${result.entity.split(':')[1]}/${
                                result.id
                              }`}
                              target="_blank"
                            >
                              <span>{result.entity.split(':')[1]}</span>
                            </Link>
                          </li>
                        );
                      })}
                    </ul>
                  </Callout>
                )}

                {/* Update Results */}
                {updateResults.length > 0 && (
                  <Callout intent="primary" icon={null} title="Updated Records">
                    <ul>
                      {updateResults?.map((result: any, index: number) => {
                        return (
                          <li key={index}>
                            <Link
                              to={`/${result.entity.split(':')[0]}/${result.entity.split(':')[1]}/${
                                result.id
                              }`}
                              target="_blank"
                            >
                              <span>{result.entity.split(':')[1]}</span>
                            </Link>
                          </li>
                        );
                      })}
                    </ul>
                  </Callout>
                )}
              </SectionCard>
            </Section>
          }
        />
      </MultistepDialog>
    </>
  );
};

const mapState = (state: any) => ({
  recordFormReducer: state.recordFormReducer,
  userReducer: state.userReducer,
});

const mapDispatch = (dispatch: any) => ({
  alertMessage: (params: { body: string; type: string }) => dispatch(displayMessage(params)),
  updateRecordInReducer: (params: { record: DbRecordEntityTransform }) =>
    dispatch(updateRecordInShortList(params)),
  closeForm: () => dispatch(closeRecordForm()),
});

export default connect(mapState, mapDispatch)(RecordStageChangeDialog);
