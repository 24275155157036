import { FC, useState } from 'react';
import { connect } from 'react-redux';
import { Button, Drawer, Section } from '@blueprintjs/core';
import { Col, Row } from 'antd';

import { SchemaActionEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/action/schema.action.entity';
import { SchemaEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/schema.entity';

import SchemaActionBuilder from './SchemaActionBuilder';
import SchemaActionPermissions from './SchemaActionPermissions';
import SchemaActionHelpDrawer from './SchemaActionHelpDrawer';

interface Props {
  schema: SchemaEntity | undefined;
  schemaAction: SchemaActionEntity | undefined;
  onClose?: () => void;
  onUpdate?: () => void;
}

const SchemaActionConfiguration: FC<Props> = (props: Props) => {
  const closeDrawer = props.onClose || (() => {});

  const { schema, schemaAction, onUpdate } = props;

  const [activeTab, setActiveTab] = useState<'ACTION_BUILDER' | 'PERMISSIONS'>('ACTION_BUILDER');
  const [isHelpDrawerOpen, setIsHelpDrawerOpen] = useState<boolean>(false);

  const closeHelpDrawer = () => {
    setIsHelpDrawerOpen(false);
  };

  return (
    <Drawer title="Action Definition" isOpen={!!schemaAction} onClose={closeDrawer} size="90%">
      <SchemaActionHelpDrawer isOpen={isHelpDrawerOpen} onClose={closeHelpDrawer} />
      <Section
        style={{ height: 'calc(100vh - 40px)' }}
        rightElement={
          <Button
            style={{ marginLeft: 10 }}
            text="Help"
            icon="help"
            onClick={() => setIsHelpDrawerOpen(true)}
          />
        }
        title={
          <Row justify="center">
            <Col span={24} style={{ textAlign: 'center' }}>
              <Button
                intent="primary"
                outlined={activeTab !== 'ACTION_BUILDER'}
                onClick={() => setActiveTab('ACTION_BUILDER')}
              >
                Action Builder
              </Button>
              <Button
                intent="primary"
                outlined={activeTab !== 'PERMISSIONS'}
                onClick={() => setActiveTab('PERMISSIONS')}
              >
                Permissions
              </Button>
            </Col>
          </Row>
        }
      >
        {/* Action Builder */}
        <div style={{ display: activeTab === 'ACTION_BUILDER' ? 'block' : 'none' }}>
          <SchemaActionBuilder schema={schema} schemaAction={schemaAction} onUpdate={onUpdate} />
        </div>

        {/* Permissions */}
        <div style={{ display: activeTab === 'PERMISSIONS' ? 'block' : 'none' }}>
          <SchemaActionPermissions schema={schema} schemaAction={schemaAction} />
        </div>
      </Section>
    </Drawer>
  );
};

const mapState = (state: any) => ({});

const mapDispatch = (dispatch: any) => ({});

export default connect(mapState, mapDispatch)(SchemaActionConfiguration);
