import { useEffect, useState } from 'react';
import {
  Alert,
  Button,
  ButtonGroup,
  Card,
  CardList,
  Classes,
  InputGroup,
  Intent,
  Section,
  SectionCard,
  Spinner,
  Tag,
} from '@blueprintjs/core';
import { v4 as uuidV4 } from 'uuid';

import { SchemaActionEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/action/schema.action.entity';
import { PipelineEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/pipeline/pipeline.entity';
import { SchemaEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/schema.entity';

import { httpDelete, httpGet, httpPost, httpPut } from '@core/http/requests';
import { displayMessage } from '@legacy/core/messages/store/reducers';
import { initializeSharedForm } from '@legacy/components/SharedForm/store/actions';
import FormModal, { FormReducerSubmitEvt } from '@legacy/components/SharedForm/SharedFormModal';

import { useAction } from '@core/hooks/useAction';
import { getErrorMessage } from '../../../../../utils/errors';

import { SchemaDetailsProps } from '../types';
import { Empty } from '../Empty';

import { getFormFields } from './formFields';
import SchemaActionConfiguration from './SchemaActionConfiguration';

const uuid = uuidV4();

const getActionType = (action: any) => {
  if (action.isCreate) {
    return 'CREATE';
  } else if (action.isUpdate) {
    return 'UPDATE';
  } else if (action.isStepFlow) {
    return 'TRANSITION';
  } else {
    return 'NONE';
  }
};

export const ActionsSection = ({ schema, schemaTypeId }: SchemaDetailsProps) => {
  const initializeForm = useAction(initializeSharedForm);
  const alertMessage = useAction(displayMessage);

  const [search, setSearch] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [actions, setActions] = useState<any[]>([]);
  const [pipeline, setPipeline] = useState<PipelineEntity>();
  const [selectedAction, setSelectedAction] = useState<SchemaActionEntity>();
  const [actionToRemove, setActionToRemove] = useState<SchemaActionEntity>();
  const [isRequesting, setIsRequesting] = useState(false);
  const [refetch, setRefetchActions] = useState(0);
  const refetchActions = () => setRefetchActions((refetch) => refetch + 1);

  const filteredActions = actions
    .filter((action) => {
      const schemaTypeFilter = schemaTypeId === 'all' ? true : action.schemaTypeId === schemaTypeId;
      const nameFilter = action.name.toLocaleLowerCase().includes(search?.toLowerCase());

      return schemaTypeFilter && nameFilter;
    })
    .sort((a, b) => a.name.localeCompare(b.name));

  useEffect(() => {
    if (!schema?.id) return;

    let mounted = true;

    setIsLoading(true);
    Promise.all([getSchemaActions(schema.id), getSchemaPipelines(schema, schemaTypeId)])
      .then(([actions, pipelines]) => {
        if (!mounted) return;

        setActions(actions);
        setPipeline(pipelines[0]);
        setIsLoading(false);
      })
      .catch((error) => {
        if (!mounted) return;

        const message = getErrorMessage(error);
        alertMessage({
          body: 'Could not retrieve schema details. ' + message,
          type: 'error',
        });
        setIsLoading(false);
        setActions([]);
      });

    return () => {
      mounted = false;
    };
  }, [schema?.id, refetch, schemaTypeId]);

  const getSchemaActions = async (schemaId: string): Promise<SchemaActionEntity[]> => {
    // TODO: this endpoint should be /schemas/${schemaId}/actions
    return await httpGet(`SchemaModule/v1.0/schemas-actions/schema/${schemaId}`).then((res) => {
      return res.data.data || [];
    });
  };

  const getSchemaPipelines = async (
    schema: SchemaEntity,
    schemaTypeId?: string | null,
  ): Promise<PipelineEntity[]> => {
    const schemaType = schema.types.find((type) => type.id === schemaTypeId);
    return await httpGet(
      `SchemaModule/v1.0/pipelines/bymodule/${schema?.moduleName}/${schema?.entityName}${
        schemaType ? `?schemaType=${schemaType.name}` : '?schemaType='
      }`,
    ).then((res: any) => {
      return res.data?.data || [];
    });
  };

  const deleteSchemaAction = async () => {
    if (!actionToRemove) return;

    setIsRequesting(true);
    await httpDelete(`SchemaModule/v1.0/schemas-actions/${actionToRemove.id}`, {});
    refetchActions();
    setIsRequesting(false);
  };

  const showActionsForm = (action?: SchemaActionEntity) => {
    initializeForm({
      showModal: true,
      formUUID: uuid,
      title: action?.id ? 'Edit Action' : 'Create Action',
      formFields: getFormFields(schema, action, schemaTypeId === 'all' ? null : schemaTypeId),
      entityName: 'SchemaAction',
    });
  };

  const handleFormSubmit = async (params: FormReducerSubmitEvt) => {
    const { type, ...data } = params.data;
    if (type === 'CREATE') {
      data.isCreate = true;
      data.isUpdate = false;
      data.isStepFlow = false;
    }

    if (type === 'UPDATE') {
      data.isCreate = false;
      data.isUpdate = true;
      data.isStepFlow = false;
    }

    if (type === 'FLOW') {
      data.isCreate = false;
      data.isUpdate = false;
      data.isStepFlow = true;
    }

    if (schemaTypeId !== 'all' && !data.schemaTypeId) {
      data.schemaTypeId = schemaTypeId;
    }

    if (params.id) {
      // update action
      try {
        await httpPut(`SchemaModule/v1.0/schemas-actions/${params.id}`, data);
        alertMessage({
          body: 'Action updated',
          type: 'success',
        });
        refetchActions();
      } catch (error: any) {
        const message = getErrorMessage(error);
        alertMessage({
          body: 'Could not update action. ' + message,
          type: 'error',
        });
      }
    } else {
      // create action
      try {
        await httpPost(`SchemaModule/v1.0/schemas-actions`, { ...data, schemaTypeId });
        alertMessage({
          body: 'Action created',
          type: 'success',
        });
        refetchActions();
      } catch (error: any) {
        const message = getErrorMessage(error);
        alertMessage({
          body: 'Could not create action. ' + message,
          type: 'error',
        });
      }
    }
  };

  return (
    <>
      <Section
        title="Actions"
        className="actions-section"
        rightElement={
          <>
            <InputGroup
              style={{ maxWidth: 170 }}
              leftIcon="search"
              placeholder="Search"
              type="search"
              value={search}
              onChange={(e: any) => setSearch(e.target.value)}
            />
            <Button
              outlined
              icon="plus"
              text="Add"
              intent="success"
              style={{ borderRadius: 5 }}
              onClick={() => showActionsForm()}
            />
          </>
        }
      >
        <SectionCard padded={false} className="limited-height">
          {isLoading && (
            <div className="loading-page">
              <Spinner />
            </div>
          )}
          {actions.length === 0 ? (
            <Empty icon="search" text="No actions" />
          ) : (
            <CardList bordered={false} compact>
              {filteredActions.map((action) => (
                <Card key={action.id} interactive compact>
                  <span>{action.name}</span>
                  <Tag round minimal intent="primary" fill={false}>
                    {getActionType(action)}
                  </Tag>
                  <span className="actions">
                    <ButtonGroup>
                      <Button
                        minimal
                        small
                        icon="annotation"
                        onClick={() => showActionsForm(action)}
                      />
                      <Button small minimal icon="code" onClick={() => setSelectedAction(action)} />

                      <Button
                        small
                        minimal
                        intent={Intent.DANGER}
                        icon="trash"
                        className={Classes.TEXT_MUTED}
                        onClick={() => setActionToRemove(action)}
                      />
                    </ButtonGroup>
                  </span>
                </Card>
              ))}
            </CardList>
          )}
        </SectionCard>
      </Section>

      <FormModal formUUID={uuid} onSubmitEvent={handleFormSubmit} />

      <SchemaActionConfiguration
        schema={schema}
        schemaAction={selectedAction}
        onClose={() => setSelectedAction(undefined)}
        onUpdate={refetchActions}
      />

      {!!actionToRemove && (
        <Alert
          isOpen={true}
          canEscapeKeyCancel
          canOutsideClickCancel
          intent={Intent.DANGER}
          loading={isRequesting}
          icon="trash"
          cancelButtonText="Cancel"
          confirmButtonText="Remove"
          onClose={() => setActionToRemove(undefined)}
          onConfirm={deleteSchemaAction}
        >
          <h3 style={{ marginTop: 0, marginBottom: '1rem' }}>Remove Action</h3>
          <p>Are you sure you want to remove this action?</p>
        </Alert>
      )}
    </>
  );
};
