import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { SchemaModuleEntityTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.entity.types';
import { SchemaModuleTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.types';
import { Button, Col, Divider, Input, Modal, Row, Select, Spin, Tooltip, notification } from 'antd';
import type { NotificationArgsProps } from 'antd';
import { FC, useContext, useEffect, useRef, useState } from 'react';
import { OrderNetworkDevicesContext } from '../index';
import ModuleEntityIcon from '@legacy/core/theme/ModuleEntityIcon';
import { httpPost } from '@core/http/requests';
import {
  SET_ADD_NETWORK_DEVICE_MODAL_DATA,
  SET_ADD_NETWORK_DEVICE_MODAL_VISIBLE,
} from '../store/constants';
// Barcode feature
import Webcam from 'react-webcam';
import { BrowserMultiFormatReader } from '@zxing/library';
import { CameraOutlined } from '@ant-design/icons';
interface Props {
  orderItem: DbRecordEntityTransform;
  onUpdated?: () => void;
  disabled?: boolean;
}
const { SERVICE_MODULE } = SchemaModuleTypeEnums;
const { CUSTOMER_DEVICE_ROUTER } = SchemaModuleEntityTypeEnums;
type modelType = 'EERO' | 'LINKSYS' | 'ASUS' | 'ARRIS' | undefined;
type NotificationPlacement = NotificationArgsProps['placement'];
const DeactivatedRouter: FC<Props> = (props: Props) => {
  const { orderItem, onUpdated, disabled } = props;
  const [serialNumber, setSerialNumber] = useState<string>('');
  const [WiFiSSID, setWiFiSSID] = useState<string>('');
  const [WiFiPassword, setWiFiPassword] = useState<string>('');
  const [isCreatingRecord, setIsCreatingRecord] = useState<boolean>(false);
  const [selectedModel, setSelectedModel] = useState<modelType>(undefined);
  const { state, dispatch } = useContext(OrderNetworkDevicesContext);
  /* ==== BarCode Feature Start ==== */
  const [api, contextHolder] = notification.useNotification();
  const openNotification = (
    placement: NotificationPlacement,
    title: string,
    message: string,
    type: string,
  ) => {
    if (type === 'info') {
      api.info({
        message: title,
        description: message,
        placement,
      });
    }
    if (type === 'error') {
      api.error({
        message: title,
        description: message,
        placement,
      });
    }
  };
  const webcamRef = useRef<Webcam>(null);
  const [isWaitingForCameraPermission, setIsWaitingForCameraPermission] = useState<boolean>(false);
  const [isSerialModalOpen, setIsSerialModalOpen] = useState<boolean>(false);
  const [isModelModalOpen, setIsModelModalOpen] = useState<boolean>(false);
  const [mediaStream, setMediaStream] = useState<MediaStream | null>(null);
  const models = ['EERO', 'LINKSYS', 'ASUS', 'ARRIS'];
  const startScan = async (isModel = false) => {
    console.log('startScan::isModel: ', isModel);
    if (webcamRef.current && webcamRef.current.video) {
      const video = webcamRef.current.video!;
      const codeReader = new BrowserMultiFormatReader();
      try {
        const stream = video.srcObject as MediaStream;
        setMediaStream(stream);
        const result = await codeReader.decodeFromVideoElement(video);
        console.log('result', result);
        if (isModel) {
          console.log('startScan::models', models, models.includes(result.getText()));
          if (models.includes(result.getText())) {
            setSelectedModel(result.getText() as modelType);
          } else {
            openNotification(
              'top',
              'Error',
              `Scanned value is not a valid model. Please scan again or select manually.`,
              'error',
            );
          }
        } else {
          setSerialNumber(result.getText());
        }
      } catch (err) {
        console.error('Error scanning the bar code:', err);
        openNotification(
          'top',
          'Error',
          `Error scanning the bar code for ${isModel ? 'model' : 'serial number'}`,
          'error',
        );
      } finally {
        codeReader.reset();
        if (isModel) {
          handleCloseModal(isModel);
        } else {
          handleCloseModal();
        }
      }
    } else {
      console.error('webcamRef is not ready');
    }
  };
  const handleCloseModal = (isModel = false) => {
    if (mediaStream) {
      mediaStream.getTracks().forEach((track) => track.stop());
      setMediaStream(null);
    }
    if (isModel) {
      setIsModelModalOpen(false);
    } else {
      setIsSerialModalOpen(false);
    }
  };
  useEffect(() => {
    if (!isWaitingForCameraPermission && (isSerialModalOpen || isModelModalOpen)) {
      startScan(isModelModalOpen);
    }
  }, [isWaitingForCameraPermission, isSerialModalOpen, isModelModalOpen]);
  const handleOpenModal = async (isModel = false) => {
    setIsWaitingForCameraPermission(true);
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        video: { facingMode: 'environment' },
      });
      const video = webcamRef.current?.video;
      if (video) {
        video.srcObject = stream;
      }
      setMediaStream(stream);
      if (isModel) {
        setIsModelModalOpen(true);
      } else {
        setIsSerialModalOpen(true);
      }
    } catch (err) {
      console.error('Error accessing the camera:', err);
      openNotification(
        'top',
        'Error',
        `Unable to access the camera. Please check your device settings.`,
        'error',
      );
    } finally {
      setIsWaitingForCameraPermission(false);
    }
  };
  /* ==== BarCode Feature End ==== */
  const handleSerialNumberChange = (e: any) => {
    setSerialNumber(e.target.value);
  };
  const activateONT = () => {
    setIsCreatingRecord(true);
    httpPost(`ServiceModule/v2.0/network/router/device/${orderItem.id}/link`, {
      entity: `${SERVICE_MODULE}:${CUSTOMER_DEVICE_ROUTER}`,
      properties: {
        SerialNumber: serialNumber,
        Model: selectedModel,
        WifiPassword: WiFiPassword,
        WifiSSID: WiFiSSID,
      },
    })
      .then((res: any) => {
        setIsCreatingRecord(false);
        const response = res.data?.data;
        if (response?.conflict) {
          dispatch({ type: SET_ADD_NETWORK_DEVICE_MODAL_VISIBLE, payload: true });
          dispatch({ type: SET_ADD_NETWORK_DEVICE_MODAL_DATA, payload: response });
        } else {
          onUpdated && onUpdated();
        }
      })
      .catch((err: any) => {
        setIsCreatingRecord(false);
      });
  };
  const handleWiFiSSIDCHange = (e: any) => {
    setWiFiSSID(e.target.value);
  };
  const handleWiFiPasswordHange = (e: any) => {
    setWiFiPassword(e.target.value);
  };
  return (
    <div
      style={{
        borderRadius: 2,
        background: '#f5f5f5',
        border: '1px solid #e2e2e2',
        padding: '15px 15px',
      }}
    >
      {contextHolder}
      <Row justify="space-between" align="middle">
        <Col>
          <ModuleEntityIcon moduleName={SERVICE_MODULE} entityName={CUSTOMER_DEVICE_ROUTER} />
          <span style={{ fontWeight: 600 }}>Router Device</span>
        </Col>
        <Col>
          <Button
            type="primary"
            style={{ marginRight: 10 }}
            disabled={
              disabled ||
              isCreatingRecord ||
              (!serialNumber && !selectedModel && !WiFiSSID && !WiFiPassword)
            }
            size="small"
            ghost
            onClick={() => {
              setSerialNumber('');
              setSelectedModel(undefined);
              setWiFiSSID('');
              setWiFiPassword('');
            }}
          >
            Clear
          </Button>
          <Button
            icon={<i className="bi bi-power" />}
            type="primary"
            size="small"
            onClick={activateONT}
            disabled={
              disabled ||
              serialNumber.length === 0 ||
              WiFiSSID?.length! === 0 ||
              WiFiPassword?.length! === 0 ||
              !selectedModel ||
              isCreatingRecord
            }
          >
            Add
          </Button>
        </Col>
      </Row>
      <Row justify="space-between" gutter={12}>
        <Col xs={24}>
          <Divider style={{ marginTop: 15, marginBottom: 15, borderColor: '#e2e2e2' }} />
        </Col>
        {/* Serial Number */}
        <Col xs={24} md={12}>
          <Input
            disabled={disabled}
            placeholder="Enter Serial No."
            allowClear
            value={serialNumber}
            onChange={handleSerialNumberChange}
            suffix={
              <Tooltip title="Scan bar code">
                <CameraOutlined
                  style={{ cursor: 'pointer', fontSize: 16 }}
                  onClick={() => handleOpenModal(false)}
                />
              </Tooltip>
            }
          />
        </Col>
        {/* Router Model */}
        <Col xs={24} md={12}>
          <Select
            allowClear
            disabled={disabled}
            style={{ width: 'calc(100% - 40px)', marginRight: '8px' }}
            placeholder="Select Model"
            onClear={() => setSelectedModel(undefined)}
            value={selectedModel}
            onSelect={setSelectedModel}
          >
            <Select.Option value="EERO">Eero</Select.Option>
            <Select.Option value="LINKSYS">Linksys</Select.Option>
            <Select.Option value="ASUS">Asus</Select.Option>
            <Select.Option value="ARRIS">Arris</Select.Option>
          </Select>
          <Tooltip title="Scan model bar code">
            <CameraOutlined
              style={{ cursor: 'pointer', fontSize: 16 }}
              onClick={() => handleOpenModal(true)}
            />
          </Tooltip>
        </Col>
        {/* Wifi SSID */}
        <Col xs={24} md={12} style={{ marginTop: 10 }}>
          <Input
            disabled={disabled}
            placeholder="Enter WiFi SSID"
            allowClear
            value={WiFiSSID}
            onChange={handleWiFiSSIDCHange}
          />
        </Col>
        {/* Wifi Password */}
        <Col xs={24} md={12} style={{ marginTop: 10 }}>
          <Input
            disabled={disabled}
            placeholder="Enter WiFi Password"
            allowClear
            value={WiFiPassword}
            onChange={handleWiFiPasswordHange}
          />
        </Col>
      </Row>
      {/* Modal for Serial Number Scan */}
      <Modal
        title="Scan Serial Number"
        open={isSerialModalOpen}
        onCancel={() => handleCloseModal()}
        onClose={() => handleCloseModal()}
        footer={null}
        centered
      >
        {isWaitingForCameraPermission ? (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              height: '300px',
            }}
          >
            <Spin size="large" />
            <p>Waiting for camera approval</p>
          </div>
        ) : (
          <Webcam
            ref={webcamRef}
            audio={false}
            videoConstraints={{
              facingMode: 'environment',
            }}
            style={{
              width: '100%',
              borderRadius: '8px',
            }}
          />
        )}
      </Modal>
      {/* Modal for Model Scan */}
      <Modal
        title="Scan Model"
        open={isModelModalOpen}
        onCancel={() => handleCloseModal(true)}
        onClose={() => handleCloseModal(true)}
        footer={null}
        centered
      >
        {isWaitingForCameraPermission ? (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              height: '300px',
            }}
          >
            <Spin size="large" />
            <p>Waiting for camera approval</p>
          </div>
        ) : (
          <Webcam
            ref={webcamRef}
            audio={false}
            videoConstraints={{
              facingMode: 'environment',
            }}
            style={{
              width: '100%',
              borderRadius: '8px',
            }}
          />
        )}
      </Modal>
    </div>
  );
};

export default DeactivatedRouter;
