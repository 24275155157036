import {
  EntityTitle,
  NonIdealState,
  NonIdealStateIconSize,
  Section,
  SectionCard,
} from '@blueprintjs/core';
import { Col, Row } from 'antd';
import { SchemaDetailsProps } from '../types';
import React from 'react';
import dayjs from 'dayjs';

export const DetailsSection = ({
  schema,
  rightElement,
}: SchemaDetailsProps & {
  rightElement?: React.ReactElement;
}) => {
  return (
    <Section
      className="top-section"
      title={<span style={{ fontSize: '1.2em', lineHeight: '24px' }}>{schema.name}</span>}
      subtitle="Schema Details"
      rightElement={rightElement}
    >
      <Row>
        <Col span={12}>
          <SectionCard className="auto" padded={false}>
            <Row style={{ padding: 15 }}>
              <Col span={12} style={{ marginTop: 5 }}>
                <EntityTitle title="Schema Name" subtitle={<span>{schema.name}</span>} />
                <br />
                <EntityTitle title="Module Name" subtitle={<span>{schema.moduleName}</span>} />
              </Col>

              <Col span={12} style={{ marginTop: 5 }}>
                <EntityTitle
                  title="Description"
                  subtitle={<span>{schema.description || 'None'}</span>}
                />
                <br />
                <EntityTitle title="Entity Name" subtitle={<span>{schema.entityName}</span>} />
              </Col>

              <Col span={12} style={{ marginTop: 15 }}>
                <EntityTitle
                  title="Created At"
                  subtitle={
                    <span>
                      {schema.createdAt
                        ? dayjs(schema.createdAt).format('DD/MM/YYYY HH:mm:ss')
                        : 'Unknown'}
                    </span>
                  }
                />
                <br />
                <EntityTitle
                  title="Version"
                  subtitle={
                    <span>
                      {
                        // @ts-ignore
                        schema?.version ? schema?.version : 'Unknown'
                      }
                    </span>
                  }
                />
              </Col>

              <Col span={12} style={{ marginTop: 15 }}>
                <EntityTitle
                  title="Updated At"
                  subtitle={
                    <span>
                      {schema.updatedAt
                        ? dayjs(schema.updatedAt).format('DD/MM/YYYY HH:mm:ss')
                        : 'Unknown'}
                    </span>
                  }
                />
              </Col>
            </Row>
          </SectionCard>
        </Col>
        <Col span={12} style={{ borderLeft: 'solid 1px rgba(17, 20, 24, 0.15)' }}>
          <SectionCard className="auto" padded={false}>
            <Row style={{ padding: 15 }}>
              <Col span={24}>
                <h3 style={{ fontWeight: 500, margin: 0 }}>Schema Activity</h3>
              </Col>

              <Col span={24} style={{ height: 150, marginTop: 15 }}>
                <NonIdealState
                  title="No Activity found"
                  icon="history"
                  iconSize={NonIdealStateIconSize.SMALL}
                />
              </Col>
            </Row>
          </SectionCard>
        </Col>
      </Row>
    </Section>
  );
};
