import { FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Col, Skeleton } from 'antd';
import { Button, Section } from '@blueprintjs/core';
import Editor from '@monaco-editor/react';

import { SchemaColumnEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/column/schema.column.entity';
import { SchemaEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/schema.entity';
import { SchemaActionEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/action/schema.action.entity';

import { ISchemaActionUpdate, updateSchemaAction } from '@legacy/core/schemas/store/actions';
import { displayMessage } from '@legacy/core/messages/store/reducers';
import { isSystemAdmin } from '@core/helpers/rbacRules';

import { DefaultSchemaActionTemplate, V2DefaultSchemaActionFlowTemplate } from './V2Template';

interface Props {
  schemaAction: SchemaActionEntity | undefined;
  schema: SchemaEntity | undefined;
  onUpdate?: () => void;
  updateAction: (payload: any, cb: any) => void;
  alertMessage: (params: { body: string; type: string }) => void;
  userReducer: any;
}

const SchemaActionBuilder: FC<Props> = (props: Props) => {
  const {
    schemaAction,
    schema,
    onUpdate = () => {},
    updateAction,
    alertMessage,
    userReducer,
  } = props;

  const [JSONEditorValue, setJSONEditorValue] = useState<any>();
  const [JSONInvalid, setJSONInvalid] = useState<boolean>(false);
  const [isSavingDefinition, setIsSavingDefinition] = useState<boolean>(false);

  const isJsonValid = (str: any) => {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  };

  useEffect(() => {
    if (schemaAction) {
      setJSONEditorValue(JSON.stringify(schemaAction.definition, null, '\t'));
    }
  }, [schemaAction]);

  useEffect(() => {
    if (JSONEditorValue?.length > 0 && isJsonValid(JSONEditorValue)) {
      setJSONInvalid(false);
    } else if (JSONEditorValue?.length !== 0 && !isJsonValid(JSONEditorValue)) {
      setJSONInvalid(true);
    } else {
      setJSONInvalid(false);
    }
  }, [JSONEditorValue]);

  const saveDefinition = () => {
    if (schemaAction && JSONEditorValue) {
      setIsSavingDefinition(true);
      updateAction(
        {
          ...schemaAction,
          schemaActionId: schemaAction.id,
          definition: JSON.parse(JSONEditorValue),
        },
        () => {
          alertMessage({ body: 'Schema Action Updated', type: 'success' });
          setIsSavingDefinition(false);
          onUpdate();
        },
      );
    }
  };

  function setJSONEditorTheme(monaco: any) {
    monaco.editor.defineTheme('odinstyle', {
      base: 'vs',
      inherit: true,
      rules: [
        {
          token: 'comment',
          foreground: '#5d7988',
          fontStyle: 'italic',
        },
      ],
      colors: {
        'editor.background': '#f5f5f5',
      },
    });
  }

  const addTemplateToDefinition = (template: 'DEFAULT' | 'FLOW') => {
    if (schema && template === 'DEFAULT') {
      const template = DefaultSchemaActionTemplate(schema.moduleName, schema.entityName);
      setJSONEditorValue(JSON.stringify(template, null, '\t'));
    } else if (schema && template === 'FLOW') {
      const template = V2DefaultSchemaActionFlowTemplate(schema.moduleName, schema.entityName);
      setJSONEditorValue(JSON.stringify(template, null, '\t'));
    }
  };

  const isJsonString = (str: any) => {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  };

  const canAddColumns = () => {
    if (schemaAction?.isStepFlow) return false;
    else if (JSONEditorValue && isJsonString(JSONEditorValue)) {
      const parsed = JSON.parse(JSONEditorValue);
      if (parsed?.formDefinition) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const addAllColumns = () => {
    if (schema) {
      let formFields: any[] = [];

      const isRequired = (schemaColumn: SchemaColumnEntity) => {
        return !!schemaColumn.validators.some(
          (validator: { type: string }) => validator.type === 'REQUIRED',
        );
      };

      let sortedSchemaColumns = schema.columns?.sort(
        (a: SchemaColumnEntity, b: SchemaColumnEntity) => (a.position > b.position ? 1 : -1),
      );

      sortedSchemaColumns.forEach((column: SchemaColumnEntity) => {
        if (!column.isHidden) {
          formFields.push({
            name: column.name,
            label: column.name,
            type: 'formField',
            required: isRequired(column),
            order: column.position,
          });
        }
      });

      let parsed: any = JSON.parse(JSONEditorValue);
      parsed.formDefinition[0].formFields = formFields;
      setJSONEditorValue(JSON.stringify(parsed, null, '\t'));
    }
  };

  return (
    <Section
      title="Definition"
      style={{ overflowY: 'scroll', height: '100%' }}
      rightElement={
        <>
          {JSONInvalid && <Button intent="danger" icon="error" text="Invalid JSON" />}

          {/* Add Form Fields */}
          {isSystemAdmin(userReducer) && canAddColumns() && (
            <Button
              disabled={JSONInvalid || isSavingDefinition}
              onClick={addAllColumns}
              icon="add-to-artifact"
              text="Add Form Fields"
            />
          )}

          {isSystemAdmin(userReducer) && (
            <Button
              disabled={JSONInvalid || isSavingDefinition}
              text="Add Template"
              icon="new-layers"
              onClick={() => {
                if (schemaAction?.isStepFlow) {
                  addTemplateToDefinition('FLOW');
                } else {
                  addTemplateToDefinition('DEFAULT');
                }
              }}
            />
          )}

          <Button
            intent="primary"
            icon="floppy-disk"
            disabled={JSONInvalid || isSavingDefinition}
            loading={isSavingDefinition}
            onClick={saveDefinition}
          >
            Save
          </Button>
        </>
      }
    >
      <Col span={24}></Col>
      <Skeleton loading={!schema || !schemaAction}>
        {schemaAction && (
          <Editor
            height="calc(100vh - 145px)"
            theme="odinstyle"
            width="100%"
            defaultLanguage="json"
            value={JSONEditorValue}
            defaultValue={JSON.stringify(schemaAction?.definition, null, '\t')}
            beforeMount={setJSONEditorTheme}
            onChange={(value: any) => setJSONEditorValue(value)}
            options={{
              wordWrap: 'on',
              minimap: { enabled: false },
            }}
          />
        )}
      </Skeleton>
    </Section>
  );
};

const mapState = (state: any) => ({
  userReducer: state.userReducer,
});

const mapDispatch = (dispatch: any) => ({
  updateAction: (payload: ISchemaActionUpdate, cb: any) =>
    dispatch(updateSchemaAction(payload, cb)),
  alertMessage: (params: { body: string; type: string }) => dispatch(displayMessage(params)),
});

export default connect(mapState, mapDispatch)(SchemaActionBuilder);
