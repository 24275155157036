import { Button, Section } from '@blueprintjs/core';
import { IGetSchemaById } from '@d19n/temp-fe-d19n-models/dist/rabbitmq/rabbitmq.interfaces';
import {
  Checkbox,
  Col,
  Divider,
  Form,
  Input,
  InputNumber,
  Popconfirm,
  Row,
  Select,
  Switch,
} from 'antd';
import React, { FC, useContext, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  deleteSchemaById,
  getSchemaByIdRequest,
  listSchemasRequest,
  updateSchemaRequest,
} from '@legacy/core/schemas/store/actions';
import { ISchemaReducer } from '@legacy/core/schemas/store/reducer';
import { errorNotification } from '@legacy/core/notifications/store/reducers';
import './styles.scss';
import { FormInstance } from 'antd/lib/form';
import { SchemaDetailsContext } from '@core/modules/ControlPanelModule/containers/SchemaManager/SchemaDetailsView';
import { titleCaseList } from '@legacy/modules/SchemaManagerModule/containers/Schemas/FormFields';

const { Option } = Select;

interface Props {
  notifyError: any;
  updateSchema: any;
  data: any;
  deleteSchema: any;
  getSchema: any;
  userReducer: any;
  listSchemas: () => void;
  schemaReducer: ISchemaReducer;
}

const DEFAULT_FORM_VALUES = {
  assignable: false,
  canSaveUpdate: false,
  dbSchema: '',
  deleteUrl: '',
  description: '',
  entityName: '',
  getUrl: '',
  groupAccessEnabled: false,
  hasTitle: false,
  icon: '',
  iconColor: '',
  isHidden: false,
  isRequesting: false,
  isSequential: false,
  isStatic: false,
  isTitleRequired: false,
  isTitleUnique: false,
  isVisibleInGlobalNav: false,
  isVisibleInTabs: false,
  menuLabel: '',
  moduleName: '',
  name: '',
  position: 0,
  postUrl: '',
  putUrl: '',
  queryable: false,
  recordDefaultOwnerId: '',
  recordNumber: 0,
  recordNumberPrefix: '',
  replicateable: false,
  retrievable: false,
  searchUrl: '',
  searchable: false,
  titleCase: '',
  triggerable: false,
  undeletable: false,
  updateable: false,
  upsertOnCreate: true,
};

const SchemaSettingsSection: FC<Props> = (props: Props) => {
  const formRef = React.createRef<FormInstance>();
  const { notifyError, updateSchema, getSchema, listSchemas, deleteSchema } = props;
  const { schema, updatedSchema, setUpdatedSchema } = useContext(SchemaDetailsContext);

  useEffect(() => {
    if (schema) {
      console.log('🛠️ %cdebug: Schema', 'color:salmon', schema);
    }
  }, [schema]);

  // const saveChanges = async () => {
  //
  //   try {
  //     if (!!this.formRef.current) {
  //       await this.formRef.current.validateFields();
  //       const formErrors = formRef.current ? formRef.current.getFieldsError() : [];
  //       const hasErrors = formErrors.filter(({ errors }:any) => errors.length).length > 0;
  //       if (hasErrors) {
  //         return notifyError({
  //           message: 'form has errors, fix them and resubmit',
  //           validation: null,
  //           data: null,
  //         });
  //       } else {
  //         this.setState({ isRequesting: true });
  //         updateSchema({ data: this.state, schemaId: data.id }, (result: any) => {
  //           this.setStateValue(result);
  //           this.setState({ isRequesting: false });
  //           this.setState({ canSaveUpdate: false });
  //           // Fetch back schema, so we can store it back to shortlist
  //           getSchema({ schemaId: data?.id });
  //           // Fetch back schema list, this is important to update the navigation structure
  //           listSchemas();
  //         });
  //       }
  //     }
  //   } catch (e) {
  //     console.error(e);
  //   }
  // };

  const handleDeleteSchema = () => {
    deleteSchema({ schemaId: schema?.id });
  };

  const initialValue = () => {
    if (schema) {
      return schema;
    } else {
      return DEFAULT_FORM_VALUES;
    }
  };

  const handleFormChange = (changedFields: any, allFields: any) => {
    const fieldName = changedFields[0]?.name[0];
    let fieldValue = changedFields[0]?.value;

    setUpdatedSchema({
      ...updatedSchema,
      [fieldName]: fieldValue,
    });
  };

  const renderForm = () => {
    return (
      <Row>
        <Col span={24}>
          <Form
            layout={'vertical'}
            ref={formRef}
            initialValues={initialValue}
            onFieldsChange={handleFormChange}
          >
            <Col span={24} offset={4} style={{ marginBottom: 10 }}>
              {/* Schema Definition Section */}
              <Row gutter={12} style={{ marginTop: 15, marginBottom: 10 }}>
                <Col span={20}>
                  <Divider orientation="left" orientationMargin={0}>
                    <span style={{ fontSize: '1.3em', fontWeight: 600 }}>Schema Definition</span>
                  </Divider>
                </Col>
              </Row>

              <Row gutter={12}>
                <Col span={10}>
                  {/* Name */}
                  <Form.Item
                    className="form-item"
                    initialValue={schema?.name}
                    label="Name"
                    name="name"
                  >
                    <Input placeholder="Name" defaultValue={schema?.name} />
                  </Form.Item>
                  {/* Description */}
                  <Form.Item
                    className="form-item"
                    initialValue={schema?.description}
                    label="Description"
                    name="description"
                  >
                    <Input placeholder="Description" defaultValue={schema?.description} />
                  </Form.Item>

                  {/* DB Schema */}
                  <Form.Item
                    className="form-item"
                    initialValue={schema?.dbSchema || 'public'}
                    label="DB Schema"
                    name="dbSchema"
                  >
                    <Input placeholder="DB Schema" defaultValue={schema?.dbSchema || 'public'} />
                  </Form.Item>
                </Col>

                <Col span={10}>
                  {/* Module Name */}
                  <Form.Item
                    initialValue={schema?.moduleName}
                    label="Module Name"
                    name="moduleName"
                  >
                    <Input placeholder="Module Name" disabled={true} />
                  </Form.Item>

                  {/* Entity name */}
                  <Form.Item
                    initialValue={schema?.entityName}
                    label="Entity Name"
                    name="entityName"
                  >
                    <Input placeholder="Entity Name" disabled={true} />
                  </Form.Item>
                </Col>
              </Row>

              {/* Schema Configuration Section */}
              <Row style={{ marginTop: 15 }}>
                <Col span={20}>
                  <Divider
                    style={{ marginTop: 5, marginBottom: 25 }}
                    orientation="left"
                    orientationMargin={0}
                  >
                    Record Title Settings
                  </Divider>
                </Col>

                <Col span={10}>
                  {/* Title Case */}
                  <Form.Item initialValue={schema?.titleCase} label="Title Case" name="titleCase">
                    <Select defaultValue={schema?.titleCase} allowClear>
                      {titleCaseList.map((tc: { value: any; label: string }) => (
                        <Option key={tc.value} value={tc.value}>
                          {tc.label}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>

                  {/* Has Title? */}
                  <Form.Item
                    initialValue={schema?.hasTitle}
                    name="hasTitle"
                    label={null}
                    valuePropName="checked"
                  >
                    <Checkbox defaultChecked={schema?.hasTitle}>Has Title</Checkbox>
                  </Form.Item>

                  {/* Is title Required? */}
                  <Form.Item
                    initialValue={schema?.isTitleRequired}
                    name="isTitleRequired"
                    label={null}
                    valuePropName="checked"
                  >
                    <Checkbox defaultChecked={schema.isTitleRequired}>Is Title Required</Checkbox>
                  </Form.Item>

                  {/* Is Title Unique */}
                  <Form.Item
                    initialValue={schema?.isTitleUnique}
                    name="isTitleUnique"
                    label={null}
                    valuePropName="checked"
                  >
                    <Checkbox defaultChecked={schema?.isTitleUnique}>Is Title Unique</Checkbox>
                  </Form.Item>
                </Col>
              </Row>

              <Row style={{ marginTop: 20 }}>
                <Col span={20}>
                  <Divider
                    style={{ marginTop: 5, marginBottom: 25 }}
                    orientation="left"
                    orientationMargin={0}
                  >
                    Record Number Settings
                  </Divider>
                </Col>
              </Row>
              <Row gutter={12}>
                <Col span={10}>
                  <Form.Item
                    className="form-item"
                    label="Starting Record Number"
                    name="recordNumber"
                    initialValue={schema?.recordNumber}
                  >
                    <InputNumber
                      placeholder="Starting Record Number"
                      type="number"
                      min={0}
                      style={{ width: '100%' }}
                    />
                  </Form.Item>

                  {/* Auto-increment Record Numbers */}

                  {/* V2 */}
                  <Form.Item
                    initialValue={schema?.isSequential}
                    name="isSequential"
                    label={null}
                    valuePropName="checked"
                  >
                    <Checkbox defaultChecked={schema?.isSequential}>
                      Auto-increment Record Numbers
                    </Checkbox>
                  </Form.Item>

                  {/* V1 */}
                  {/*<Form.Item*/}
                  {/*  name="isSequential"*/}
                  {/*  label="Auto-increment Record Numbers"*/}
                  {/*  initialValue={schema?.isSequential}*/}
                  {/*>*/}
                  {/*  <Switch defaultValue={schema?.isSequential} />*/}
                  {/*</Form.Item>*/}
                </Col>
                <Col span={10}>
                  <Form.Item
                    label="Record Number Prefix"
                    name="recordNumberPrefix"
                    initialValue={schema?.recordNumberPrefix}
                  >
                    <Input
                      defaultValue={schema?.recordNumberPrefix}
                      placeholder="Record Number Prefix"
                    />
                  </Form.Item>
                </Col>
              </Row>

              {/* Schema Configuration */}
              <Row>
                <Col span={20} style={{ marginTop: 10, marginBottom: 10 }}>
                  <Divider orientation="left" orientationMargin={0}>
                    <span style={{ fontSize: '1.3em', fontWeight: 600 }}>Schema Configuration</span>
                  </Divider>
                </Col>
              </Row>

              <Row gutter={12}>
                <Col span={6}>
                  {/* Upsert on Create */}
                  <Form.Item
                    initialValue={schema?.upsertOnCreate}
                    name="upsertOnCreate"
                    label={null}
                    valuePropName="checked"
                  >
                    <Checkbox defaultChecked={schema?.upsertOnCreate}>Upsert on create</Checkbox>
                  </Form.Item>

                  {/* Is Visible in Tabs */}
                  <Form.Item
                    initialValue={schema?.isVisibleInTabs}
                    name="isVisibleInTabs"
                    label={null}
                    valuePropName="checked"
                  >
                    <Checkbox checked={schema?.isVisibleInTabs}>Is Visible In Tabs</Checkbox>
                  </Form.Item>

                  {/* Group Access */}
                  <Form.Item
                    initialValue={schema?.groupAccessEnabled}
                    label="Enable Group Access"
                    name="groupAccessEnabled"
                  >
                    <Switch defaultValue={updatedSchema?.groupAccessEnabled} />
                  </Form.Item>
                </Col>

                <Col span={6}>
                  {/* Queryable */}
                  {/*<Form.Item*/}
                  {/*  initialValue={schema?.queryable}*/}
                  {/*  name="queryable"*/}
                  {/*  label={null}*/}
                  {/*  valuePropName="checked"*/}
                  {/*>*/}
                  {/*  <Checkbox defaultChecked={schema?.queryable}>Queryable</Checkbox>*/}
                  {/*</Form.Item>*/}

                  {/* Replicateable */}
                  {/*<Form.Item*/}
                  {/*  initialValue={schema?.replicateable}*/}
                  {/*  name="replicateable"*/}
                  {/*  label={null}*/}
                  {/*  valuePropName="checked"*/}
                  {/*>*/}
                  {/*  <Checkbox defaultChecked={schema?.replicateable}>Replicateable</Checkbox>*/}
                  {/*</Form.Item>*/}

                  {/* Retrievable */}
                  {/*<Form.Item*/}
                  {/*  initialValue={schema?.retrievable}*/}
                  {/*  name="retrievable"*/}
                  {/*  label={null}*/}
                  {/*  valuePropName="checked"*/}
                  {/*>*/}
                  {/*  <Checkbox defaultChecked={schema.retrievable}>Retrievable</Checkbox>*/}
                  {/*</Form.Item>*/}

                  {/* Searchable */}
                  <Form.Item
                    initialValue={schema?.searchable}
                    name="searchable"
                    label={null}
                    valuePropName="checked"
                  >
                    <Checkbox defaultChecked={schema?.searchable}>Searchable</Checkbox>
                  </Form.Item>

                  {/* Triggerable */}
                  <Form.Item
                    initialValue={schema?.triggerable}
                    name="triggerable"
                    label={null}
                    valuePropName="checked"
                  >
                    <Checkbox defaultChecked={schema?.triggerable}>Triggerable</Checkbox>
                  </Form.Item>

                  {/* Undeletable */}
                  {/*<Form.Item*/}
                  {/*  initialValue={schema?.undeletable}*/}
                  {/*  name="undeletable"*/}
                  {/*  label={null}*/}
                  {/*  valuePropName="checked"*/}
                  {/*>*/}
                  {/*  <Checkbox defaultChecked={schema?.undeletable}>Undeletable</Checkbox>*/}
                  {/*</Form.Item>*/}

                  {/* Updateable */}
                  {/*<Form.Item*/}
                  {/*  initialValue={schema?.updateable}*/}
                  {/*  name="updateable"*/}
                  {/*  label={null}*/}
                  {/*  valuePropName="checked"*/}
                  {/*>*/}
                  {/*  <Checkbox defaultChecked={schema?.updateable}>Updateable</Checkbox>*/}
                  {/*</Form.Item>*/}
                </Col>

                <Col span={6}>
                  {/* Is Visible in Navbar */}
                  <Form.Item
                    initialValue={schema?.isVisibleInGlobalNav}
                    name="isVisibleInGlobalNav"
                    label={null}
                    valuePropName="checked"
                  >
                    <Checkbox checked={schema?.isVisibleInGlobalNav}>Is Visible In Navbar</Checkbox>
                  </Form.Item>

                  {updatedSchema?.isVisibleInGlobalNav && (
                    <Form.Item label="Menu Label" initialValue={schema?.menuLabel} name="menuLabel">
                      <Input placeholder="Menu Label" defaultValue={schema?.menuLabel} />
                    </Form.Item>
                  )}
                </Col>

                {/*    {isSystemAdmin(userReducer) ? (*/}
                {/*      <Row>*/}
                {/*        <Col span={24} style={{ marginBottom: 10 }}>*/}
                {/*          <label>Schema icon</label>*/}
                {/*        </Col>*/}

                {/*        <Col span={24}>*/}
                {/*          <Row>*/}
                {/*            <Col span={12}>*/}
                {/*              <ModuleEntityIcon*/}
                {/*                overrideIconColor={this.state.iconColor}*/}
                {/*                overrideIcon={this.state.icon}*/}
                {/*                moduleName={data?.moduleName}*/}
                {/*                entityName={data?.entityName}*/}
                {/*              />*/}
                {/*            </Col>*/}
                {/*            <Col span={12} style={{ textAlign: 'right' }}>*/}
                {/*              {this.state.icon !== data?.icon ||*/}
                {/*              this.state.iconColor !== data?.iconColor ? (*/}
                {/*                <Button*/}
                {/*                  icon={<UndoOutlined />}*/}
                {/*                  onClick={() => {*/}
                {/*                    this.setState({*/}
                {/*                      icon: data?.icon,*/}
                {/*                      iconColor: data?.iconColor,*/}
                {/*                    });*/}
                {/*                  }}*/}
                {/*                >*/}
                {/*                  Undo Changes*/}
                {/*                </Button>*/}
                {/*              ) : (*/}
                {/*                <></>*/}
                {/*              )}*/}
                {/*            </Col>*/}
                {/*          </Row>*/}
                {/*        </Col>*/}

                {/*        <Col span={24} style={{ marginBottom: 5, marginTop: 20 }}>*/}
                {/*          <label>Icon type</label>*/}
                {/*        </Col>*/}

                {/*        <Col span={24}>*/}
                {/*          <Select*/}
                {/*            size="large"*/}
                {/*            style={{ width: '100%', marginTop: 4 }}*/}
                {/*            showSearch*/}
                {/*            placeholder="Select an icon"*/}
                {/*            defaultValue={data?.icon}*/}
                {/*            optionFilterProp="children"*/}
                {/*            onChange={(e: any) => {*/}
                {/*              this.setState({ icon: e });*/}
                {/*            }}*/}
                {/*            filterOption={(input: any, option: any) =>*/}
                {/*              option?.value?.indexOf(input.toLowerCase()) > -1*/}
                {/*            }*/}
                {/*            dropdownRender={(menu) => (*/}
                {/*              <>*/}
                {/*                {menu}*/}
                {/*                <Divider style={{ margin: '4px 0' }} />*/}
                {/*                <Row>*/}
                {/*                  <Col span={24} style={{ textAlign: 'center' }}>*/}
                {/*                    <a*/}
                {/*                      href="https://icons.getbootstrap.com"*/}
                {/*                      target="_blank"*/}
                {/*                      rel="noreferrer"*/}
                {/*                    >*/}
                {/*                      <Button>View all icons</Button>*/}
                {/*                    </a>*/}
                {/*                  </Col>*/}
                {/*                </Row>*/}
                {/*              </>*/}
                {/*            )}*/}
                {/*          >*/}
                {/*            {bootstrapIcons.map((icon: string) => {*/}
                {/*              return (*/}
                {/*                <Select.Option key={icon} value={icon}>*/}
                {/*                  <i style={{ marginRight: 5 }} className={`bi bi-${icon}`} /> {icon}*/}
                {/*                </Select.Option>*/}
                {/*              );*/}
                {/*            })}*/}
                {/*          </Select>*/}
                {/*        </Col>*/}

                {/*        <Col span={24} style={{ marginTop: 20 }}>*/}
                {/*          <Row>*/}
                {/*            <Col span={24}>*/}
                {/*              <label>Icon color</label>*/}
                {/*            </Col>*/}
                {/*            <Col span={24} style={{ paddingTop: 10 }}>*/}
                {/*              <HexColorPicker*/}
                {/*                color={this.state.iconColor || 'white'}*/}
                {/*                onChange={this.handleColorChange}*/}
                {/*                style={{ width: '100%' }}*/}
                {/*              />*/}
                {/*            </Col>*/}
                {/*          </Row>*/}
                {/*        </Col>*/}
                {/*      </Row>*/}
                {/*    ) : (*/}
                {/*      <></>*/}
                {/*    )}*/}
                {/*  </Col>*/}
              </Row>
            </Col>
          </Form>
        </Col>

        <Col span={24}>
          <Divider />
        </Col>

        <Col span={24} style={{ marginBottom: 30, marginTop: 10 }}>
          <Row align="middle" justify="center" gutter={24}>
            {/* Delete schema */}
            <Col>
              <Popconfirm
                title="Are you sure you want to delete this schema?"
                onConfirm={handleDeleteSchema}
                okText="Yes"
                cancelText="No"
              >
                <Button
                  key="2"
                  intent="danger"
                  style={{ borderRadius: 5 }}
                  minimal
                  text="Delete Schema"
                  large
                />
              </Popconfirm>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  };

  return (
    <Section className="settings-section" title="Settings">
      {renderForm()}
    </Section>
  );
};

const mapState = (state: any) => ({
  userReducer: state.userReducer,
  schemaReducer: state.schemaReducer,
});

const mapDispatch = (dispatch: any) => ({
  updateSchema: (params: any, cb: any) => dispatch(updateSchemaRequest(params, cb)),
  getSchema: (payload: IGetSchemaById, cb: any) => dispatch(getSchemaByIdRequest(payload, cb)),
  deleteSchema: (params: any) => dispatch(deleteSchemaById(params)),
  notifyError: (params: any) => dispatch(errorNotification(params)),
  listSchemas: () => dispatch(listSchemasRequest()),
});

export default connect(mapState, mapDispatch)(SchemaSettingsSection);
