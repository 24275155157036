import { DbRecordAssociationCreateUpdateDto } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/association/dto/db.record.association.create.update.dto';
import { SchemaColumnOptionEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/column/option/schema.column.option.entity';
import { SchemaColumnTypes } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/column/types/schema.column.types';
import { SchemaColumnValidatorEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/column/validator/schema.column.validator.entity';
import { Checkbox, Form, FormInstance, Input, Select, Switch } from 'antd';

import 'moment/locale/en-gb';
import OrganizationUserLookup from '@legacy/modules/IdentityManagerModule/containers/User/OrganizationUserLookup';
import PipelineStageSelect from '@legacy/modules/SchemaManagerModule/containers/Pipeline/PipelineStageSelect';
import SchemaTypeFormSelect from '@legacy/modules/SchemaManagerModule/containers/Types/SchemaTypeFormSelect';
import FileFormField from '@legacy/core/records/components/Files/FileFormField';
import { setIsChecked } from '@core/helpers/validateDataTypes';
import FormColorPicker from '../../FormColorPicker';

const { TextArea } = Input;
const { Option } = Select;

export interface InputChangeParams {
  id: string;
  entity: string;
  value: any;
  association?: DbRecordAssociationCreateUpdateDto;
}

export interface SharedFormField {
  allowClear?: boolean;
  allowSearch?: boolean;
  allowSort?: boolean;
  allowMultiple?: boolean;
  className: string;
  customValidation?: boolean;
  customValidationCondition?: any;
  customValidationMessage?: string;
  defaultValue?: string | number | string[];
  description?: string;
  entity?: string;
  form?: FormInstance;
  getIsRequired?: () => boolean;
  getOptions?: () => { label: string; value: any }[];
  handleInputChange: any;
  id?: string;
  initialValue: string | null;
  isDisabled: boolean;
  isHidden: boolean;
  isRequired: boolean;
  label: string;
  message?: string;
  name?: string;
  options?: SchemaColumnOptionEntity[];
  property: string;
  schemaId?: string;
  type: string;
  validators?: SchemaColumnValidatorEntity[];
  value: any;
}

export default function renderFormField(field: SharedFormField) {
  let isPasswordVisible: boolean = false;

  if (!field.isHidden) {
    switch (field.type) {
      case SchemaColumnTypes.PASSWORD:
        return (
          <Form.Item
            key={field.property}
            name={field.property}
            label={field.label}
            labelCol={{ span: 24 }}
            initialValue={field.value}
            rules={[
              {
                required: field.isRequired,
                message: field.message,
              },
              field.customValidation
                ? {
                    validator(rule, value, callback) {
                      if (value === undefined) {
                        callback();
                      } else if (value.length < 8 || value.length > 20) {
                        callback(field.customValidationMessage);
                      } else {
                        return callback(undefined);
                      }
                    },
                  }
                : {},
            ]}
            className={field.className}
          >
            <Input.Password
              visibilityToggle={{
                visible: isPasswordVisible,
                onVisibleChange: () => {
                  isPasswordVisible = !isPasswordVisible;
                },
              }}
              disabled={field.isDisabled}
              defaultValue={field.value}
              placeholder={field.label}
              onChange={(e) =>
                field.handleInputChange({
                  property: field.property,
                  value: e.target.value,
                })
              }
              autoComplete="new-password"
            />
          </Form.Item>
        );

      case SchemaColumnTypes.EMAIL:
        return (
          <Form.Item
            key={field.property}
            name={field.property}
            label={field.label}
            labelCol={{ span: 24 }}
            initialValue={field.value}
            rules={[{ required: field.isRequired, message: field.message }]}
            className={field.className}
          >
            <Input
              type="email"
              disabled={field.isDisabled}
              defaultValue={field.value}
              placeholder={field.label}
              onChange={(e) =>
                field.handleInputChange({
                  property: field.property,
                  value: e.target.value,
                })
              }
            />
          </Form.Item>
        );
      case SchemaColumnTypes.TEXT:
        return (
          <Form.Item
            key={field.property}
            name={field.property}
            label={field.label}
            labelCol={{ span: 24 }}
            initialValue={field.value}
            rules={[
              {
                required: field.isRequired,
                message: field.message,
              },
              field.customValidation
                ? {
                    validator(rule, value, callback) {
                      if (value === undefined) {
                        callback();
                      } else if (value.length < field.customValidationCondition) {
                        callback(field.customValidationMessage);
                      } else {
                        return callback(undefined);
                      }
                    },
                  }
                : {},
            ]}
          >
            <Input
              type="text"
              disabled={field.isDisabled}
              defaultValue={field.value}
              placeholder={field.description || field.label}
              onChange={(e) =>
                field.handleInputChange({
                  property: field.property,
                  value: e.target.value,
                })
              }
            />
          </Form.Item>
        );
      case SchemaColumnTypes.TEXT_LONG:
        return (
          <Form.Item
            key={field.property}
            name={field.property}
            label={field.label}
            labelCol={{ span: 24 }}
            initialValue={field.initialValue}
            rules={[{ required: field.isRequired }]}
          >
            <TextArea
              rows={4}
              disabled={field.isDisabled}
              defaultValue={field.defaultValue}
              placeholder={field.description || field.message}
              onChange={(e) =>
                field.handleInputChange({
                  property: field.property,
                  value: e.target.value,
                })
              }
            />
          </Form.Item>
        );

      case SchemaColumnTypes.FILE_MULTIPLE:
        return (
          <Form.Item
            key={field.property}
            name={field.property}
            hasFeedback
            label={field.label}
            labelCol={{ span: 24 }}
            initialValue={!!field.initialValue ? field.initialValue : undefined}
            rules={[{ required: field.isRequired }]}
          >
            <FileFormField
              mode="FILE_MULTIPLE"
              fileIds={field.initialValue ? field.initialValue.split(',') : []}
              parentSchemaId={field.schemaId}
              onUpdate={(elem: any[]) => {
                if (elem !== undefined && !elem?.includes(undefined)) {
                  field.form &&
                    field.form?.setFieldsValue({
                      [field?.property!]: elem,
                    });
                  field.handleInputChange({
                    property: field.property,
                    value: elem.length > 0 ? elem.join(',') : null,
                  });
                }
              }}
            />
          </Form.Item>
        );

      case SchemaColumnTypes.NUMBER:
      case SchemaColumnTypes.CURRENCY:
      case SchemaColumnTypes.PERCENT:
        return (
          <Form.Item
            key={field.property}
            name={field.property}
            label={field.label}
            labelCol={{ span: 24 }}
            initialValue={field.value}
            rules={[{ required: field.isRequired, message: field.message }]}
          >
            <Input
              type="number"
              disabled={field.isDisabled}
              defaultValue={field.value}
              placeholder={field.description}
              onChange={(e) =>
                field.handleInputChange({
                  property: field.property,
                  value: parseInt(e.target.value),
                })
              }
            />
          </Form.Item>
        );

      case SchemaColumnTypes.ENUM:
        return (
          <Form.Item
            key={field.id ? field.id.toString() : field.property}
            name={field.property}
            label={field.label}
            labelCol={{ span: 24 }}
            initialValue={!!field.initialValue ? field.initialValue : null}
            rules={[
              {
                required: field.getIsRequired ? field.getIsRequired() : field.isRequired,
                message: field.message,
              },
            ]}
          >
            <Select
              allowClear={field.allowClear}
              placeholder="Select Option"
              key={field.id}
              defaultValue={!!field.defaultValue ? field.defaultValue : null}
              style={{ width: '100%' }}
              disabled={field.isDisabled}
              mode={field.allowMultiple ? 'multiple' : undefined}
              // If field has enableSort set to true then sort it
              filterSort={(optionA: any, optionB: any) =>
                field.allowSort &&
                (optionA?.label ?? '')
                  .toLowerCase()
                  .localeCompare((optionB?.label ?? '').toLowerCase())
              }
              // If field has enableFiltering set to true, then show search input
              showSearch={field.allowSearch}
              filterOption={(input, option) =>
                field.allowSearch && option?.label
                  ? String(option?.label)?.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  : false
              }
              onChange={(val) =>
                field.handleInputChange({
                  property: field.property,
                  value: val,
                })
              }
              getPopupContainer={(trigger) => trigger.parentNode}
            >
              {field.options ? (
                field.options.map((opt: any) => (
                  <Option value={opt.value} label={opt.label}>
                    {opt.label}
                  </Option>
                ))
              ) : field.getOptions ? (
                field.getOptions()?.map((opt: any) => (
                  <Option value={opt.value} label={opt.value}>
                    {opt.label}
                  </Option>
                ))
              ) : (
                <Option value="">no options</Option>
              )}
            </Select>
          </Form.Item>
        );
      case 'CHECKBOX':
        return (
          <Form.Item
            key={field.property}
            name={field.property}
            label={field.label}
            labelCol={{ span: 24 }}
            initialValue={setIsChecked(field)}
            rules={[{ required: field.isRequired, message: field.message }]}
          >
            <Checkbox
              disabled={field.isDisabled}
              key={field.property}
              defaultChecked={setIsChecked(field)}
              onChange={(val) =>
                field.handleInputChange({
                  property: field.property,
                  value: val.target.checked,
                })
              }
            />
          </Form.Item>
        );

      case 'SWITCH':
        return (
          <Form.Item
            key={field.property}
            name={field.property}
            label={field.label}
            labelCol={{ span: 24 }}
            initialValue={field.value}
            rules={[{ required: field.isRequired, message: field.message }]}
          >
            <Switch
              disabled={field.isDisabled}
              key={field.property}
              defaultChecked={setIsChecked(field)}
              onChange={(val) =>
                field.handleInputChange({
                  property: field.property,
                  value: val,
                })
              }
            />
          </Form.Item>
        );

      case 'USER_LOOKUP':
        return (
          <Form.Item
            key={field.property}
            name={field.property}
            label={field.label}
            labelCol={{ span: 24 }}
            initialValue={field.value}
            rules={[{ required: field.isRequired, message: field.message }]}
          >
            <OrganizationUserLookup
              defaultValue={field.value}
              key={field.property}
              onChange={(val) =>
                field.handleInputChange({
                  property: field.property,
                  value: val,
                })
              }
            />
          </Form.Item>
        );

      case 'SCHEMA_TYPE_SELECT':
        return (
          <Form.Item
            key={field.property}
            name={field.property}
            label={field.label}
            labelCol={{ span: 24 }}
            initialValue={field.value}
            rules={[{ required: field.isRequired, message: field.message }]}
          >
            <SchemaTypeFormSelect
              defaultValue={field.value}
              key={field.property}
              onChange={(val) =>
                field.handleInputChange({
                  property: field.property,
                  value: val,
                })
              }
            />
          </Form.Item>
        );
      case 'PIPELINE_STAGE_SELECT':
        return (
          <Form.Item
            key={field.property}
            name={field.property}
            label={field.label}
            labelCol={{ span: 24 }}
            initialValue={field.initialValue}
            rules={[{ required: field.isRequired, message: field.message }]}
          >
            <PipelineStageSelect
              initialValue={field.initialValue}
              key={field.property}
              onChange={(val) =>
                field.handleInputChange({
                  property: field.property,
                  value: val,
                })
              }
            />
          </Form.Item>
        );

      case 'COLOR_PICKER':
        return (
          <Form.Item
            key={field.property}
            name={field.property}
            label={field.label}
            labelCol={{ span: 24 }}
            initialValue={field.initialValue}
            rules={[{ required: field.isRequired, message: field.message }]}
          >
            <FormColorPicker
              initialValue={field.initialValue}
              key={field.property}
              onChange={(val) =>
                field.handleInputChange({
                  property: field.property,
                  value: val,
                })
              }
            />
          </Form.Item>
        );

      default:
        return (
          <Form.Item
            key={field.property}
            name={field.property}
            label={field.label}
            labelCol={{ span: 24 }}
            initialValue={field.value}
            rules={[{ required: field.isRequired, message: field.message }]}
          >
            <Input
              type="text"
              disabled={field.isDisabled}
              defaultValue={field.value}
              placeholder={field.description}
              onChange={(e) =>
                field.handleInputChange({
                  id: `${field.schemaId}#${field.name}`,
                  entity: field.entity,
                  value: e.target.value,
                })
              }
            />
          </Form.Item>
        );
    }
  }
}
