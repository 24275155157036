import {
  SchemaModuleEntityTypeEnums,
} from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.entity.types';
import { FC, useContext } from 'react';
import BillingRequestDetailView from '@core/modules/BillingModule/views/BillingRequestDetailView';
import CreditNoteDetailView from '@core/modules/BillingModule/views/CreditNoteDetailView';
import InvoiceDetailView from '@core/modules/BillingModule/views/InvoiceDetailView';
import InvoiceItemDetailView from '@core/modules/BillingModule/views/InvoiceItemDetailView';
import PaymentMethodDetailView from '@core/modules/BillingModule/views/PaymentMethodDetailView';
import PaymentMethodRefundDetailView from '@core/modules/BillingModule/views/PaymentMethodRefundDetailView';
import TransactionDetailView from '@core/modules/BillingModule/views/TransactionDetailView';
import AccountDetailView from '@core/modules/CrmModule/views/AccountDetailView';
import AddressDetailView from '@core/modules/CrmModule/views/AddressDetailView';
import ContactDetailView from '@core/modules/CrmModule/views/ContactDetailView';
import ContactIdentityDetailView from '@core/modules/CrmModule/views/ContactIdentityDetailView';
import CrmDataSetDetailView from '@core/modules/CrmModule/views/CrmDataSetDetailView';
import LeadDetailView from '@core/modules/CrmModule/views/LeadDetailView';
import VisitDetailView from '@core/modules/CrmModule/views/VisitDetailView';
import ServiceAppointmentConfigDetailView
  from '@core/modules/FieldServiceModule/views/ServiceAppointmentConfigDetailView';
import WorkOrderDetailView from '@core/modules/FieldServiceModule/views/WorkOrderDetailView';
import FileDetailView from '@core/modules/FileModule/views/FileDetailView';
import BillingAdjustmentDetailView from '@core/modules/OrderModule/views/BillingAdjustmentDetailView';
import OrderDetailView from '@core/modules/OrderModule/views/OrderDetailView';
import ApplicationForPaymentDetailView from '@netomnia/modules/ProjectModule/views/ApplicationForPaymentDetailView';
import ExchangeDetailView from '@netomnia/modules/ProjectModule/views/ExchangeDetailView';
import FeatureDetailView from '@netomnia/modules/ProjectModule/views/FeatureDetailView';
import FeaturePriceEntryDetailView from '@netomnia/modules/ProjectModule/views/FeaturePriceEntryDetailView';
import PaymentNoticeDetailView from '@netomnia/modules/ProjectModule/views/PaymentNoticeDetailView';
import ProgramDetailView from '@netomnia/modules/ProjectModule/views/ProgramDetailView';
import ProjectDetailView from '@netomnia/modules/ProjectModule/views/ProjectDetailView';
import RegionDetailView from '@netomnia/modules/ProjectModule/views/RegionDetailView';
import WorkListDetailView from '@netomnia/modules/ProjectModule/views/WorkListDetailView';
import CaseDetailView from '@core/modules/SupportModule/views/CaseDetailView';
import DetailView from '../../views/DetailView';
import { DetailViewContext } from '../DetailViewContextProvider';
import UserDetailView from '@core/modules/IdentityModule/views/UserDetailView';
import { DownloadRecordPdf } from '../DownloadRecordPdf';
import GanttProgramDetailView from '@core/modules/ProjectModule/views/GanttProgramDetailView';
import GanttProjectDetailView from '@core/modules/ProjectModule/views/GanttProjectDetailView';

const {
  CONTACT,
  LEAD,
  ACCOUNT,
  ADDRESS,
  CONTACT_IDENTITY,
  VISIT,
  INVOICE,
  BILLING_ADJUSTMENT,
  ORDER,
  BILLING_REQUEST,
  CREDIT_NOTE,
  INVOICE_ITEM,
  PAYMENT_METHOD,
  TRANSACTION,
  SERVICE_APPOINTMENT_CONFIG,
  WORK_ORDER,
  FILE,
  EXCHANGE,
  PROGRAM,
  PROJECT,
  REGION,
  FEATURE,
} = SchemaModuleEntityTypeEnums;

const CASE = 'Case';
const USER = 'User';

const CRM_DATASET = 'CrmDataset';
const PAYMENT_METHOD_REFUND = 'PaymentMethodRefund';
const APPLICATION_FOR_PAYMENT = 'ApplicationForPayment';
const FEATURE_PRICE_ENTRY = 'FeaturePriceEntry';
const PAYMENT_NOTICE = 'PaymentNotice';
const WORK_LIST = 'WorkList';
const GANTT_PROGRAM = 'GanttProgram';
const GANTT_PROJECT = 'GanttProject';

const DetailViewRouter: FC = () => {
  const { schema } = useContext(DetailViewContext);

  const detailViewController = () => {
    if (schema) {
      const { entityName } = schema;

      switch (entityName) {
        // CRM MODULE
        case ACCOUNT:
          return <AccountDetailView />;
        case ADDRESS:
          return <AddressDetailView />;
        case CONTACT:
          return <ContactDetailView />;
        case CONTACT_IDENTITY:
          return <ContactIdentityDetailView />;
        case LEAD:
          return <LeadDetailView />;
        case VISIT:
          return <VisitDetailView />;
        case CRM_DATASET:
          return <CrmDataSetDetailView />;
        // BILLING MODULE
        case INVOICE:
          return <InvoiceDetailView />;
        case BILLING_REQUEST:
          return <BillingRequestDetailView />;
        case CREDIT_NOTE:
          return <CreditNoteDetailView />;
        case INVOICE_ITEM:
          return <InvoiceItemDetailView />;
        case PAYMENT_METHOD:
          return <PaymentMethodDetailView />;
        case PAYMENT_METHOD_REFUND:
          return <PaymentMethodRefundDetailView />;
        case TRANSACTION:
          return <TransactionDetailView />;
        // FIELD SERVICE MODULE
        case SERVICE_APPOINTMENT_CONFIG:
          return <ServiceAppointmentConfigDetailView />;
        case WORK_ORDER:
          return <WorkOrderDetailView isQuickView />;
        // ORDER MODULE
        case ORDER:
          return <OrderDetailView />;
        case BILLING_ADJUSTMENT:
          return <BillingAdjustmentDetailView />;
        // PROJECT MODULE
        case APPLICATION_FOR_PAYMENT:
          return <ApplicationForPaymentDetailView />;
        case EXCHANGE:
          return <ExchangeDetailView />;
        case FEATURE_PRICE_ENTRY:
          return <FeaturePriceEntryDetailView />;
        case PAYMENT_NOTICE:
          return <PaymentNoticeDetailView />;
        case PROGRAM:
          return <ProgramDetailView />;
        case PROJECT:
          return <ProjectDetailView />;
        case REGION:
          return <RegionDetailView />;
        case WORK_LIST:
          return <WorkListDetailView />;
        case FEATURE:
          return <FeatureDetailView />;
        case GANTT_PROGRAM:
          return <GanttProgramDetailView />;
        case GANTT_PROJECT:
          return <GanttProjectDetailView />;
        // FILES
        case FILE:
          return <FileDetailView />;
        // IDENTITY MODULE
        case USER:
          return <UserDetailView />;
        // SUPPORT MODULE
        case CASE:
          return <CaseDetailView />;
        // DEFAULT DETAIL VIEW
        default:
          return <DetailView showCollaborators headerExtras={[<DownloadRecordPdf />]} />;
      }
    } else return <></>;
  };

  return detailViewController();
};
export default DetailViewRouter;
