import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { Col, Row } from 'antd';
import { Menu, MenuDivider, MenuItem } from '@blueprintjs/core';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import {
  getSchemaByIdRequest,
  listSchemasRequest,
  updateSchemaRequest,
} from '@legacy/core/schemas/store/actions';
import { getSchemaFromShortListBySchemaId } from '@core/helpers/schemaHelpers';
import { displayMessage } from '@legacy/core/messages/store/reducers';
import { useRequest } from '@core/hooks/useRequest';
import { getErrorMessage } from '../../../utils/errors';

import SchemaDetails from './SchemaDetails';
import './styles.scss';
import { toSentenceCase } from '@core/helpers/stringHelpers';
import { SchemaEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/schema.entity';
import { IGetSchemaById } from '@d19n/temp-fe-d19n-models/dist/rabbitmq/rabbitmq.interfaces';

type TActiveSection =
  | 'top-section'
  | 'schema-types'
  | 'schema-details'
  | 'properties-actions'
  | 'schema-links'
  | 'permissions'
  | 'pipelines'
  | 'settings';

const sectionClasses = [
  { name: 'top-section', className: '.top-section' },
  { name: 'schema-types', className: '.types-section' },
  { name: 'properties-actions', className: '.properties-section' },
  { name: 'schema-links', className: '.schema-links' },
  { name: 'permissions', className: '.permissions-section' },
  { name: 'pipelines', className: '.pipeline-section' },
  { name: 'settings', className: '.settings-section' },
];

let isScrolling = false;

export const SchemaDetailsContext = React.createContext<{
  schema: any;
  updatedSchema: any;
  setUpdatedSchema: (schema: any) => void;
  isSavingSchema: boolean;
  saveSchema: () => void;
}>({
  schema: undefined,
  updatedSchema: undefined,
  setUpdatedSchema: (): void => {},
  isSavingSchema: false,
  saveSchema: (): void => {},
});

type Props = RouteComponentProps<{ id: string }> & {
  history: any;
  match: any;
  alertMessage: (params: { body: string; type: string }) => void;
  listSchemas: () => void;
  getSchema: any;
};

const ControlPanelView: React.FC<Props> = (props: Props) => {
  const { history, match, alertMessage, listSchemas, getSchema } = props;

  const schemaShortList = useSelector((state: any) => state.schemaReducer.shortList);

  const schemaDetailsRef = React.useRef<HTMLDivElement>(null);
  const [isSchemaLoading, setIsSchemaLoading] = useState(false);
  const [currentView, setCurrentView] = useState<TActiveSection>('top-section');

  const getSchemaById = useRequest(getSchemaByIdRequest);
  const [updatedSchema, setUpdatedSchema] = useState<SchemaEntity | undefined>(undefined);
  const [isSavingSchema, setIsSavingSchema] = useState<boolean>(false);

  const schema = getSchemaFromShortListBySchemaId(schemaShortList, match.params.id);
  const updateSchema = useRequest(updateSchemaRequest);

  useEffect(() => {
    if (schema) {
      setUpdatedSchema(schema);
    }
  }, [schema]);

  // Handle intersection observer for the left navbar
  useEffect(() => {
    const handleIntersection = (entries: IntersectionObserverEntry[]) => {
      if (isScrolling) return; // Ignore updates during programmatic scrolling
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const section = sectionClasses.find((s) =>
            entry.target.classList.contains(s.className.slice(1)),
          );
          if (section) {
            setCurrentView(section.name as TActiveSection);
          }
        }
      });
    };

    const observer = new IntersectionObserver(handleIntersection, {
      root: schemaDetailsRef.current,
      rootMargin: '0px 0px -95% 0px',
      threshold: 0,
    });

    const setupObserver = () => {
      sectionClasses.forEach((section) => {
        const element = document.querySelector(section.className);
        if (element) {
          observer.observe(element);
        }
      });
    };

    const onUserScroll = () => {
      if (!isScrolling) {
        observer.disconnect();
        setupObserver();
      }
    };

    if (schemaDetailsRef.current) {
      setupObserver();
      schemaDetailsRef.current.addEventListener('scroll', onUserScroll);
    }

    return () => {
      observer.disconnect();
      schemaDetailsRef.current?.removeEventListener('scroll', onUserScroll);
    };
  }, [schemaDetailsRef.current, schema, isScrolling]);

  // Handle programmatic scrolling for the left navbar
  const programmaticScroll = (targetSelector: string) => {
    const target = document.querySelector(targetSelector);
    if (!target) return;

    isScrolling = true;
    target.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'nearest',
    });

    // Reset the `isScrolling` flag after a delay
    setTimeout(() => {
      isScrolling = false;
    }, 1000);
  };

  useEffect(() => {
    let mounted = true;
    if (!match.params?.id) return;

    setIsSchemaLoading(true);
    getSchemaById({ schemaId: match.params.id })
      .then(() => {
        if (!mounted) return;

        // setSchema(schema);
        setIsSchemaLoading(false);
      })
      .catch((error) => {
        if (!mounted) return;

        const message = getErrorMessage(error);
        alertMessage({
          body: 'Could not retrieve schema. ' + message,
          type: 'error',
        });
      });

    return () => {
      mounted = false;
    };
  }, [match.params?.id]);

  const saveSchema = async () => {
    setIsSavingSchema(true);

    try {
      const res = await updateSchema({ schemaId: schema?.id, data: updatedSchema });
      setIsSavingSchema(false);
      console.log('🛠️ %cdebug: Schema Updated', 'color:limegreen');

      // Fetch back schema, so we can store it back to shortlist
      getSchema({ schemaId: res?.id });
      listSchemas();
    } catch (e: any) {
      setIsSavingSchema(false);
    }
  };

  return (
    <Row>
      {/* Navbar */}
      <Col span={4} style={{ padding: 3, borderRight: '1px solid #DCDCDD' }}>
        <Menu style={{ background: 'transparent', position: 'sticky' }}>
          <MenuItem
            icon="arrow-left"
            style={{ padding: '8px 7px' }}
            text="Back"
            onClick={() => history.push('/ControlPanelModule/SchemaManager/')}
          />

          <MenuDivider title={schema?.name ? toSentenceCase(schema?.name) : ''} />

          <MenuItem
            style={{ marginTop: 12 }}
            text="Overview"
            disabled={isSchemaLoading}
            active={currentView === 'top-section'}
            onClick={() => {
              setCurrentView('top-section');
              programmaticScroll('.top-section');
            }}
          />

          <MenuItem
            text="Schema Types"
            disabled={isSchemaLoading}
            active={currentView === 'schema-types'}
            onClick={() => {
              setCurrentView('schema-types');
              programmaticScroll('.types-section');
            }}
          />

          <MenuItem
            text="Properties & Actions"
            disabled={isSchemaLoading}
            active={currentView === 'properties-actions'}
            onClick={() => {
              setCurrentView('properties-actions');
              programmaticScroll('.properties-section');
            }}
          />

          <MenuItem
            text="Links"
            disabled={isSchemaLoading}
            active={currentView === 'schema-links'}
            onClick={() => {
              setCurrentView('schema-links');
              programmaticScroll('.schema-links');
            }}
          />

          {/* Permissions */}
          <MenuItem
            text="Permissions"
            disabled={isSchemaLoading}
            active={currentView === 'permissions'}
            onClick={() => {
              setCurrentView('permissions');
              programmaticScroll('.permissions-section');
            }}
          />

          <MenuItem
            text="Process Pipelines"
            disabled={isSchemaLoading}
            active={currentView === 'pipelines'}
            onClick={() => {
              setCurrentView('pipelines');
              programmaticScroll('.pipeline-section');
            }}
          />
          <MenuItem
            text="Settings"
            disabled={isSchemaLoading}
            active={currentView === 'settings'}
            onClick={() => {
              setCurrentView('settings');
              programmaticScroll('.settings-section');
            }}
          />
        </Menu>
      </Col>

      {/* Content */}
      <SchemaDetailsContext.Provider
        value={{
          schema,
          updatedSchema,
          setUpdatedSchema,
          isSavingSchema,
          saveSchema,
        }}
      >
        <Col span={20} style={{ height: '95vh' }}>
          <SchemaDetails loading={isSchemaLoading} schemaDetailsRef={schemaDetailsRef} />
        </Col>
      </SchemaDetailsContext.Provider>
    </Row>
  );
};

const mapState = (state: any) => ({});

const mapDispatch = (dispatch: any) => ({
  alertMessage: (params: { body: string; type: string }) => dispatch(displayMessage(params)),
  listSchemas: () => dispatch(listSchemasRequest()),
  getSchema: (payload: IGetSchemaById, cb: any) => dispatch(getSchemaByIdRequest(payload, cb)),
});

export default connect(mapState, mapDispatch)(withRouter(ControlPanelView));
