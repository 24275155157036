import { Button, Menu, MenuItem, Popover, Tooltip } from '@blueprintjs/core';
import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { SchemaEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/schema.entity';
import { SchemaModuleEntityTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.entity.types';
import { SchemaModuleTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.types';
import { listUsers } from '@legacy/core/identity/store/actions';
import { getPipelinesByModuleAndEntity } from '@legacy/core/pipelines/store/actions';
import { TableReducer } from '@legacy/core/records/components/DynamicTable/store/reducer';
import ListActionMenuUploaderModal from '@legacy/core/records/components/Files/ListActionMenuUploaderModal';
import CoreForm from '@legacy/core/records/components/Forms/CoreForm';
import { initializeRecordForm } from '@legacy/core/records/components/Forms/store/actions';
import AssignGroupsToRecords from '@legacy/core/records/components/Groups/AssignGroupsToRecords';
import { ISearchRecords, searchRecordsRequest } from '@legacy/core/records/store/actions';
import {
  BULK_UPDATE_DB_RECORDS_REQUEST,
  CREATE_DB_RECORD_REQUEST,
  UPDATE_DB_RECORD_BY_ID_REQUEST,
} from '@legacy/core/records/store/constants';
import { IRecordReducer } from '@legacy/core/records/store/reducer';
import { ISchemaReducer } from '@legacy/core/schemas/store/reducer';
import { toggleAssignRecordToGroupModal } from '@legacy/core/userInterface/store/actions';
import { IUserInterfaceReducer } from '@legacy/core/userInterface/store/types';
import { createAccountVisible, createOrderVisible } from '@legacy/core/workflow/store/actions';
import { Modal, notification, Spin } from 'antd';
import fileDownload from 'js-file-download';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import history from '../../helpers/browserHistory';
import {
  canUserCreateRecord,
  canUserMergeRecord,
  canUserUpdateRecord,
  hasPermissions,
  isSystemAdmin,
} from '../../helpers/rbacRules';
import {
  getDefaultFields,
  getSavedFilter,
  setSearchQuery,
  setSortQuery,
} from '../../helpers/searchHelpers';
import { httpGet } from '../../http/requests';

const { CRM_MODULE } = SchemaModuleTypeEnums;

interface Props {
  schema: SchemaEntity | undefined;
  userReducer: any;
  recordReducer: IRecordReducer;
  schemaReducer: ISchemaReducer;
  recordTableReducer: TableReducer;
  initializeForm: any;
  getUsers: any;
  getPipelines: any;
  createOrderVisible: any;
  createAccountVisible: any;
  searchRecords: (params: ISearchRecords) => void;
  toggleAssignGroupModal: () => void;
  userInterfaceReducer: IUserInterfaceReducer;
}

interface State {
  isGettingFile: boolean;
  uploadModalIsVisible: boolean;
  accountFlowVisible: boolean;
}

const { ORDER_MODULE, SCHEMA_MODULE } = SchemaModuleTypeEnums;

const { ORDER } = SchemaModuleEntityTypeEnums;

const uuid = uuidv4();

class ActionMenuListView extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isGettingFile: false,
      uploadModalIsVisible: false,
      accountFlowVisible: false,
    };
  }

  toggleUploadModalVisibility = () => {
    this.setState({
      uploadModalIsVisible: !this.state.uploadModalIsVisible,
    });
  };

  toggleAccountFlowVisibility = () => {
    this.setState({
      accountFlowVisible: !this.state.accountFlowVisible,
    });
  };

  async initializeCreateForm() {
    const { initializeForm, getUsers, getPipelines, schema } = this.props;

    getUsers();

    if (schema) {
      if (schema.moduleName === ORDER_MODULE && schema.entityName === ORDER) {
        history.push(`/${ORDER_MODULE}/OrderBuilder`);
      } else {
        getPipelines({ schema: schema });

        initializeForm({
          formUUID: uuid,
          title: 'Initializing',
          showInitializing: true,
        });

        initializeForm({
          formUUID: uuid,
          title: `Create ${schema.entityName}`,
          showFormModal: true,
          isCreateReq: true,
          schema: schema,
          selected: null,
          sections: [{ name: schema.name, schema: schema }],
        });
      }
    } else {
      return initializeForm({
        title: 'Create',
        formUUID: uuid,
        showFormModal: true,
      });
    }
  }

  private async initializeBulkUpdateForm() {
    const { initializeForm, schema, getUsers, getPipelines } = this.props;

    if (schema) {
      getUsers();
      getPipelines({ schema });

      initializeForm({
        formUUID: uuid,
        title: 'Initializing',
        showInitializing: true,
      });

      initializeForm({
        formUUID: uuid,
        title: `Bulk Update ${schema.entityName}`,
        showFormModal: true,
        isBulkUpdateReq: true,
        schema: schema,
        selected: null,
        sections: [{ name: schema.name, schema: schema }],
      });
    }
  }

  private openNotificationWithIcon() {
    notification.success({
      message: 'File successfully generated',
      description:
        'To download the file, please check your email inbox and look for an email from us.',
    });
  }

  private async exportTable() {
    const { schema, schemaReducer, recordTableReducer, recordReducer } = this.props;

    if (schema && !this.state.isGettingFile) {
      this.setState({ isGettingFile: true });

      const moduleName = schema.moduleName;
      const entityName = schema.entityName;
      const savedFilter = getSavedFilter(schemaReducer, recordTableReducer, moduleName, entityName);

      let searchQuery = {
        terms: setSearchQuery(schemaReducer, recordReducer, moduleName, entityName),
        fields: getDefaultFields(schema, moduleName, entityName),
        schemas: schema.id,
        sort: setSortQuery(schemaReducer, recordReducer, moduleName, entityName),
        boolean: savedFilter?.queries,
        pageable: {
          page: 1,
          size: 9999,
        },
      };
      const { terms, schemas, fields, sort, pageable, boolean } = searchQuery;
      const pageNum = !!pageable && !!pageable.page ? Number(pageable.page) - 1 : 0;
      const sizeNum = !!pageable && !!pageable.size ? Number(pageable.size) : 25;
      const userFields = recordTableReducer.columns.map((column) => column.dataIndex);
      // adding id to export - d19-61
      userFields.push('id');
      const userFieldsString = userFields.join();
      const queryParams = `terms=${terms || ''}&boolean=${
        boolean ? JSON.stringify(boolean) : ''
      }&fields=${fields || ''}&schemas=${schemas}&page=${pageNum}&size=${sizeNum}&sort=${
        sort ? JSON.stringify(sort) : ''
      }&file=true&user_fields=${userFieldsString}`;
      // TODO: Restore after latest update
      // const path = `${schema ? schema.moduleName : 'SchemaModule'}/v1.0/db/${schema ? schema.entityName :
      // 'ALL'}/search?${queryParams}`;
      const path = `SchemaModule/v1.0/db/${
        schema ? schema.entityName : 'ALL'
      }/search?${queryParams}`;
      // const path = `SchemaModule/v1.0/db/${schema ? schema.entityName : 'ALL'}/search?${queryParams}`;
      await httpGet(path).then(
        (res) => {
          this.setState({ isGettingFile: false });
          if (res.data.sentToEmail) {
            this.openNotificationWithIcon();
          } else {
            fileDownload(res.data, `${schema ? schema.entityName : 'data'}.csv`);
          }
        },
        () => {
          this.setState({ isGettingFile: false });
        },
      );
    }
  }

  private refreshSearch() {
    const { schema, recordReducer, searchRecords } = this.props;

    if (schema && !recordReducer.isSearching) {
      const searchQuery = recordReducer.searchQuery[schema.id];

      if (searchQuery) {
        searchRecords({
          schema: schema,
          searchQuery: {
            schemas: schema.id,
            fields: searchQuery.fields,
            terms: searchQuery.terms,
            boolean: searchQuery.boolean,
            sort: searchQuery.sort,
            pageable: {
              page: 1,
            },
          },
        });
      }
    }
  }

  private handleFormSubmit(params: { event: string; results: DbRecordEntityTransform }) {
    const { schema } = this.props;
    if (schema) {
      switch (params.event) {
        case CREATE_DB_RECORD_REQUEST:
          setTimeout(() => {
            history.push(`/${schema.moduleName}/${schema.entityName}/${params.results.id}`);
          }, 500);
          break;
        case UPDATE_DB_RECORD_BY_ID_REQUEST:
          break;
        case BULK_UPDATE_DB_RECORDS_REQUEST:
          this.refreshSearch();
          break;
      }
    }
  }

  private enableMergeRecords() {
    const { recordTableReducer } = this.props;

    if (recordTableReducer.selectedItems) {
      return recordTableReducer.selectedItems.length === 2;
    }
  }

  menuItems = () => {
    const { userReducer, schema, toggleAssignGroupModal } = this.props;

    return (
      <Menu>
        {/* New */}
        <MenuItem
          text="New"
          key="new"
          disabled={schema ? !canUserCreateRecord(userReducer, schema) : false}
          onClick={() => this.initializeCreateForm()}
        />

        {schema?.entityName === 'CrmDataset' &&
          hasPermissions(userReducer, ['crmmodule.crmdataset.assign.read']) && (
            <MenuItem
              text="Assign"
              key="assign"
              onClick={() => history.push(`/${CRM_MODULE}/CrmDataset/Assign/`)}
            />
          )}

        {/* Bulk Update */}
        <MenuItem
          disabled={
            !hasPermissions(userReducer, ['records.bulkupdate']) ||
            !canUserUpdateRecord(userReducer, schema)
          }
          onClick={() => this.initializeBulkUpdateForm()}
          text="Bulk Update"
          key="bulkUpdate"
        />

        {/* Merge */}
        <MenuItem
          disabled={
            schema ? !canUserMergeRecord(userReducer, schema) && this.enableMergeRecords() : false
          }
          onClick={() => history.push(`/merge/${schema?.moduleName}/${schema?.entityName}`)}
          text="Merge"
          key="merge"
        />

        {/* Import CSV */}
        <MenuItem
          disabled={!hasPermissions(userReducer, ['records.bulkupdate'])}
          onClick={() => this.toggleUploadModalVisibility()}
          text="Import"
          key="import"
        />

        {/* Export */}
        <Tooltip
          disabled={hasPermissions(userReducer, ['records.export'])}
          content="You do not have permission to export data."
          fill
        >
          <MenuItem
            disabled={this.state.isGettingFile || !hasPermissions(userReducer, ['records.export'])}
            onClick={() => this.exportTable()}
            text="Export"
            key="export"
          />
        </Tooltip>

        {/* Assign Groups to Records */}
        <MenuItem
          disabled={
            !hasPermissions(userReducer, ['groups.assign']) ||
            !hasPermissions(userReducer, ['records.bulkupdate']) ||
            !isSystemAdmin(userReducer)
          }
          text={
            !hasPermissions(userReducer, ['records.bulkupdate']) || !isSystemAdmin(userReducer) ? (
              <span style={{ opacity: 0.3 }}>Manage Groups</span>
            ) : (
              <span onClick={() => toggleAssignGroupModal()}>Manage Groups</span>
            )
          }
          key="manageGroups"
        />

        {/* View Schema */}
        {isSystemAdmin(userReducer) && schema && (
          <Link
            to={`/ControlPanelModule/SchemaManager/${schema?.id}`}
            target="_blank"
            style={{ color: 'black', textDecoration: 'none' }}
          >
            <MenuItem key="menuItemShowSchema" text="View Schema" />
          </Link>
        )}

        {/* View Actions */}
        {isSystemAdmin(userReducer) && schema && (
          <Link
            target="_blank"
            to={`/ControlPanelModule/SchemaManager/SchemaActionsListView#entityName=${schema?.entityName}`}
            style={{ color: 'black', textDecoration: 'none' }}
          >
            <MenuItem key="menuItemViewActions" text="View Actions" />
          </Link>
        )}

        {/* View Actions */}
        {isSystemAdmin(userReducer) && schema && (
          <Link
            to={`/${SCHEMA_MODULE}/Schema/${schema?.id}#Pipelines`}
            target="_blank"
            style={{ color: 'black', textDecoration: 'none' }}
          >
            <MenuItem key="menuItemViewPipelines" text="View Pipelines" />
          </Link>
        )}
      </Menu>
    );
  };

  render() {
    const { schema, userInterfaceReducer } = this.props;

    return (
      <>
        {/* Don't show the assign groups if quick view drawer is open, because the drawer has one already. */}
        {!userInterfaceReducer.recordDrawerVisible && <AssignGroupsToRecords schema={schema} />}
        <ListActionMenuUploaderModal
          moduleName={schema?.moduleName!}
          entityName={schema?.entityName!}
          modalIsVisible={this.state.uploadModalIsVisible}
          toggleModalVisibility={this.toggleUploadModalVisibility}
        />
        <Modal open={this.state.isGettingFile} centered={true} footer={null}>
          <Spin spinning={this.state.isGettingFile}>data exporting...</Spin>
        </Modal>
        <CoreForm
          type="MODAL"
          formUUID={uuid}
          onSubmitEvent={(params: { event: string; results: DbRecordEntityTransform }) =>
            this.handleFormSubmit(params)
          }
        />
        <Popover content={<Menu>{this.menuItems()}</Menu>} placement="bottom">
          <Button alignText="left" rightIcon="caret-down" text="Actions" />
        </Popover>
      </>
    );
  }
}

const mapState = (state: any) => ({
  userReducer: state.userReducer,
  recordReducer: state.recordReducer,
  recordTableReducer: state.recordTableReducer,
  schemaReducer: state.schemaReducer,
  userInterfaceReducer: state.userInterfaceReducer,
});

const mapDispatch = (dispatch: any) => ({
  initializeForm: (params: any) => dispatch(initializeRecordForm(params)),
  getUsers: (cb: any) => dispatch(listUsers(cb)),
  getPipelines: (params: { schema: SchemaEntity }) =>
    dispatch(getPipelinesByModuleAndEntity(params)),
  createOrderVisible: () => dispatch(createOrderVisible()),
  createAccountVisible: () => dispatch(createAccountVisible()),
  searchRecords: (params: ISearchRecords) => dispatch(searchRecordsRequest(params)),
  toggleAssignGroupModal: () => dispatch(toggleAssignRecordToGroupModal()),
});

export default connect(mapState, mapDispatch)(ActionMenuListView);
