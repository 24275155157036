import { Alert, Button, Collapse, Divider, EntityTitle, Section } from '@blueprintjs/core';
import { Col, Row } from 'antd';
import dayjs from 'dayjs';
import { FC, useState } from 'react';
import { connect } from 'react-redux';
import { EditUser, editUserRequest } from '@legacy/core/identityUser/store/actions';
import SharedFormModal, {
  FormReducerSubmitEvt,
} from '@legacy/components/SharedForm/SharedFormModal';
import { initializeSharedForm } from '@legacy/components/SharedForm/store/actions';
import { httpDelete, httpPatch } from '@core/http/requests';
import { displayMessage } from '@legacy/core/messages/store/reducers';
import { getErrorMessage } from '../../../../utils/errors';
import { formFields } from '../FormFields';

interface Props {
  area: any;
  initializeForm: (params: any) => void;
  onUpdate: () => void;
  onDelete: () => void;
  alertMessage: (params: { body: string; type: string }) => void;
}

const AreaDetails: FC<Props> = (props: Props) => {
  const { area, onUpdate, initializeForm, alertMessage, onDelete } = props;
  const [isShowingFullDetails, setIsShowingFullDetails] = useState<boolean>(true);
  const [isDeleteAreaAlertVisible, setIsDeleteAreaAlertVisible] = useState<boolean>(false);
  const [copiedTextToClipboard, setCopiedTextToClipboard] = useState<boolean>(false);

  const showEditAreaForm = () => {
    const tempEl: any = area;
    let editForm: any[] = [];

    formFields.forEach((element: any) => {
      editForm.push({
        allowClear: element.allowClear,
        allowSearch: element.allowSearch,
        customValidation: element.customValidation,
        customValidationMessage: element.customValidationMessage,
        initialValue: tempEl[element.property],
        isDisabled: element.isDisabled,
        isHidden: element.isHidden,
        isRequired: element.isRequired,
        label: element.label,
        message: element.message,
        options: element.options,
        property: element.property,
        type: element.type,
        value: tempEl[element.property],
      });
    });

    initializeForm({
      formUUID: area.id,
      title: 'Edit Area',
      recordId: area?.id,
      showModal: true,
      formFields: editForm,
      entityName: 'Area',
      isUpdateReq: true,
      columns: 1,
    });
  };

  const editArea = async (body: any) => {
    try {
      return httpPatch(`IdentityModule/v2.0/areas/${area.id}`, body).then(() => {
        alertMessage({ body: 'Area updated', type: 'success' });
        onUpdate();
      });
    } catch (error: any) {
      const message = getErrorMessage(error);
      alertMessage({ body: 'Could not edit area. ' + message, type: 'error' });
    }
  };

  const handleFormSubmit = (params: any) => {
    if (params.title === 'Edit Area') {
      editArea(params.data);
    }
  };

  const deleteArea = async () => {
    try {
      await httpDelete(`IdentityModule/v2.0/areas/${area.id}`);
      alertMessage({ body: 'Area deleted', type: 'success' });
      onDelete();
    } catch (e: any) {
      const message = getErrorMessage(e);
      alertMessage({ body: 'Could not delete area. ' + message, type: 'error' });
    }
  };

  const copyTextToClipboard = (text: any) => {
    navigator.clipboard.writeText(text);
    setCopiedTextToClipboard(true);
    setTimeout(() => {
      setCopiedTextToClipboard(false);
    }, 1500);
  };

  return (
    <>
      <Row style={{ padding: 15 }}>
        <SharedFormModal
          formUUID={area.id}
          onSubmitEvent={(params: FormReducerSubmitEvt) => handleFormSubmit(params)}
        />

        <Col span={24}>
          <Row justify="space-between">
            <Col>
              <h3 style={{ marginTop: 0, lineBreak: 'anywhere' }}>{area.name}</h3>
              <span>{area.description}</span>
            </Col>
          </Row>
        </Col>

        <Col span={24} style={{ marginTop: 20, marginBottom: 0 }}>
          <Row gutter={8}>
            <Col span={12}>
              <Button
                icon="trash"
                intent="danger"
                text="Delete"
                disabled
                fill
                onClick={() => setIsDeleteAreaAlertVisible(true)}
              />
            </Col>
            <Col span={12}>
              <Button
                text="Edit"
                fill
                icon="annotation"
                onClick={(e: any) => {
                  showEditAreaForm();
                }}
              />
            </Col>
          </Row>
        </Col>

        <Col span={24} style={{ margin: '10px 0 0 0' }}>
          <Divider />
        </Col>

        {/* Area ID */}
        <Col span={24} className="detailViewSection">
          <Section
            title="Area Id"
            rightElement={
              <Button
                minimal
                small
                rightIcon={copiedTextToClipboard ? 'tick' : null}
                text={copiedTextToClipboard ? 'Copied' : 'Copy'}
                intent={copiedTextToClipboard ? 'success' : 'primary'}
                onClick={() => copyTextToClipboard(area.id)}
              />
            }
          >
            <span>{area.id}</span>
          </Section>
        </Col>

        <Col span={24} style={{ margin: '10px 0 0 0' }}>
          <Divider />
        </Col>

        {/* Full Details */}
        <Col span={24} className="detailViewSection">
          <Section
            title="Full Details"
            rightElement={
              <Button
                minimal
                small
                text={isShowingFullDetails ? 'Hide' : 'Show'}
                intent="primary"
                onClick={(e: any) => {
                  e.stopPropagation();
                  setIsShowingFullDetails(!isShowingFullDetails);
                }}
              />
            }
          >
            <Row>
              <Col span={24}>
                <Collapse isOpen={isShowingFullDetails}>
                  <Row>
                    {/* Updated At */}
                    <Col span={24} style={{ marginTop: 5 }}>
                      <EntityTitle
                        title="Updated At"
                        subtitle={
                          <span>
                            {dayjs(area.updatedAt).format('DD/MM/YYYY HH:mm:ss') || 'None'}
                          </span>
                        }
                      />
                    </Col>

                    {/* Created At */}
                    <Col span={24} style={{ marginTop: 10, marginBottom: 20 }}>
                      <EntityTitle
                        title="Created At"
                        subtitle={
                          <span>
                            {dayjs(area.createdAt).format('DD/MM/YYYY HH:mm:ss') || 'None'}
                          </span>
                        }
                      />
                    </Col>
                  </Row>
                </Collapse>
              </Col>
            </Row>
          </Section>
        </Col>
      </Row>

      {/* Delete Area Alert */}
      <Alert
        intent="danger"
        canEscapeKeyCancel
        cancelButtonText="Cancel"
        confirmButtonText="Delete"
        isOpen={isDeleteAreaAlertVisible}
        onCancel={() => setIsDeleteAreaAlertVisible(false)}
        onClose={() => setIsDeleteAreaAlertVisible(false)}
        onConfirm={deleteArea}
      >
        <p>Are you sure you want to delete this area? This action cannot be undone.</p>
      </Alert>
    </>
  );
};

const mapState = (state: any) => ({});

const mapDispatch = (dispatch: any) => ({
  initializeForm: (params: any) => dispatch(initializeSharedForm(params)),
  editUser: (params: EditUser, cb: any) => dispatch(editUserRequest(params, cb)),
  alertMessage: (params: { body: string; type: string }) => dispatch(displayMessage(params)),
});

export default connect(mapState, mapDispatch)(AreaDetails);
