import { FC, useContext } from 'react';
import { Col, Row } from 'antd';
import { DetailViewContext } from '@core/components/DetailViewContextProvider';
import HeaderDetailView from '@core/components/HeaderDetailView';
import RecordStageSequentialPipeline_V2 from '@core/components/RecordStageSequentialPipeline_V2';
import GanttChart from '@core/components/GanttChart';
import { SchemaModuleTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.types';

const GanttProjectDetailView: FC = () => {
  const { record, pipeline } = useContext(DetailViewContext);

  return (
    <Row style={{ marginTop: 1 }}>
      <Col span={24}>
        <HeaderDetailView record={record!} />

        {record?.stage && pipeline?.isSequential && (
          <RecordStageSequentialPipeline_V2
            className="record-pipeline"
            record={record}
            pipeline={pipeline}
          />
        )}
      </Col>

      <Col xs={24} style={{ padding: 15 }}>
        <GanttChart record={record} dataProcessorUrl={`${SchemaModuleTypeEnums.PROJECT_MODULE}/v1.0/GanttProject/${record?.id}`} />
      </Col>
    </Row>
  );
};

export default GanttProjectDetailView;
