import { Alert, Button, Collapse, Divider, EntityTitle, Section, Tag } from '@blueprintjs/core';
import { Col, Row } from 'antd';
import { FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { EditUser, editUserRequest } from '@legacy/core/identityUser/store/actions';
import { initializeSharedForm } from '@legacy/components/SharedForm/store/actions';
import { displayMessage } from '@legacy/core/messages/store/reducers';
import { SchemaActionEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/action/schema.action.entity';
import dayjs from 'dayjs';
import { SchemaEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/schema.entity';
import { getOdinSchemaById } from '@core/helpers/schemaHelpers';
import SchemaActionConfiguration from '@core/modules/ControlPanelModule/containers/SchemaManager/SchemaDetailsView/SchemaDetails/ActionsSection/SchemaActionConfiguration';
import { getFormFields } from '@core/modules/ControlPanelModule/containers/SchemaManager/SchemaDetailsView/SchemaDetails/ActionsSection/formFields';
import { PipelineEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/pipeline/pipeline.entity';
import { httpGet, httpPut } from '@core/http/requests';
import FormModal, { FormReducerSubmitEvt } from '@legacy/components/SharedForm/SharedFormModal';
import { getErrorMessage } from '@core/modules/ControlPanelModule/utils/errors';

interface Props {
  schemaAction: SchemaActionEntity;
  allSchemaActions: SchemaActionEntity[];
  initializeForm: (params: any) => void;
  onUpdate: (action?: any) => void;
  onDelete: () => void;
  alertMessage: (params: { body: string; type: string }) => void;
  setSelectedAction: (action: SchemaActionEntity) => void;
}

const SchemaActionDetails: FC<Props> = (props: Props) => {
  const {
    schemaAction,
    onUpdate,
    initializeForm,
    alertMessage,
    onDelete,
    allSchemaActions,
    setSelectedAction,
  } = props;

  const [schema, setSchema] = useState<SchemaEntity | undefined>(undefined);
  const [isShowingSchemaActionDetails, setIsShowingSchemaActionDetails] = useState<boolean>(true);
  const [isShowingSchemaDetails, setIsShowingSchemaDetails] = useState<boolean>(true);
  const [isUpdatingAction, setIsUpdatingAction] = useState<boolean>(false);

  const [definitionAction, setDefinitionAction] = useState<SchemaActionEntity | undefined>(
    undefined,
  );

  const [isDeleteActionAlertVisible, setIsDeleteActionAlertVisible] = useState<boolean>(false);
  const [copiedTextToClipboard, setCopiedTextToClipboard] = useState<boolean>(false);
  const [isLoadingSchema, setIsLoadingSchema] = useState<boolean>(false);
  const [pipeline, setPipeline] = useState<PipelineEntity | undefined>(undefined);

  // Find if the schema action is used in any flows
  const usedInFlows = () => {
    return allSchemaActions?.filter((action) => {
      if (action.definition?.dialogSteps) {
        const steps = action.definition.dialogSteps;
        for (const step of steps) {
          if (step.schemaActions?.includes(schemaAction.name)) {
            return true;
          }
        }
      }
      return false;
    });
  };

  const used = usedInFlows() || [];

  // On component mount, fetch schema for the schema action
  useEffect(() => {
    if (schemaAction) {
      setSchema(undefined);
      setPipeline(undefined);
      getSchemaForSchemaAction(schemaAction?.schemaId);
    }
  }, [schemaAction]);

  const getSchemaPipelines = async (schema: SchemaEntity, schemaTypeId?: string | null) => {
    try {
      const schemaType = schema.types.find((type) => type.id === schemaTypeId);
      const res = await httpGet(
        `SchemaModule/v1.0/pipelines/bymodule/${schema?.moduleName}/${schema?.entityName}${
          schemaType ? `?schemaType=${schemaType.name}` : '?schemaType='
        }`,
      );

      const pipelines = res?.data?.data || [];

      if (pipelines.length > 0) {
        setPipeline(pipelines[0]);
      }

      // console.log('🛠️ debug: Pipeline', pipelines);
    } catch (e: any) {}
  };

  const showActionsForm = (action?: SchemaActionEntity) => {
    if (schema && schemaAction) {
      initializeForm({
        showModal: true,
        formUUID: schemaAction.id,
        title: action?.id ? 'Edit Action' : 'Create Action',
        formFields: getFormFields(schema, action, schemaAction.schemaTypeId || null, pipeline),
        entityName: 'SchemaAction',
      });
    }
  };

  const handleFormSubmit = async (params: FormReducerSubmitEvt) => {
    const { type, ...data } = params.data;

    try {
      setIsUpdatingAction(true);
      const res = await httpPut(`SchemaModule/v1.0/schemas-actions/${schemaAction?.id}`, data);

      setIsUpdatingAction(false);

      const responseAction = res.data?.data;
      if (responseAction) {
        onUpdate(responseAction);
      }

      alertMessage({
        body: 'Action updated',
        type: 'success',
      });
    } catch (error: any) {
      setIsUpdatingAction(false);
      const message = getErrorMessage(error);
      alertMessage({
        body: 'Could not update action. ' + message,
        type: 'error',
      });
    }
  };

  const getSchemaForSchemaAction = async (schemaId: string) => {
    try {
      setIsLoadingSchema(true);
      const res = await getOdinSchemaById(schemaId);
      setSchema(res);
      setIsLoadingSchema(false);
      getSchemaPipelines(res, schemaAction.schemaTypeId);
    } catch (e: any) {
      setIsLoadingSchema(false);
      alertMessage({ body: e.message, type: 'error' });
    }
  };

  const copyTextToClipboard = (text: any) => {
    navigator.clipboard.writeText(text);
    setCopiedTextToClipboard(true);
    setTimeout(() => {
      setCopiedTextToClipboard(false);
    }, 1500);
  };

  const getSchemaActionType = (schemaAction: SchemaActionEntity): string => {
    if (schemaAction.isStepFlow) {
      return 'TRANSITION';
    } else if (schemaAction.isCreate) {
      return 'CREATE';
    } else if (schemaAction.isUpdate) {
      return 'UPDATE';
    } else {
      return 'UNKNOWN';
    }
  };

  const getSchemaType = (schemaAction: SchemaActionEntity): string => {
    return schema?.types?.find((t: any) => t.id === schemaAction.schemaTypeId)?.name || 'None';
  };

  return (
    <>
      <SchemaActionConfiguration
        schema={schema!}
        schemaAction={definitionAction}
        onClose={() => setDefinitionAction(undefined)}
        onUpdate={onUpdate}
      />

      <Row style={{ padding: 15 }}>
        <Col span={24}>
          <Row justify="space-between">
            <Col>
              <h3 style={{ marginTop: 0, marginBottom: 5, lineBreak: 'anywhere' }}>
                {schemaAction?.name}
              </h3>
              <span>{schemaAction?.description}</span>
            </Col>
          </Row>
        </Col>

        <Col span={24} style={{ marginTop: 20, marginBottom: 0 }}>
          <Row gutter={8}>
            <Col span={12}>
              <Button
                disabled
                icon="trash"
                intent="danger"
                text="Delete"
                fill
                onClick={() => setIsDeleteActionAlertVisible(true)}
              />
            </Col>
            <Col span={12}>
              <Button
                disabled={!schema || !schemaAction}
                loading={isUpdatingAction}
                text="Edit"
                fill
                icon="annotation"
                onClick={() => showActionsForm(schemaAction)}
              />
            </Col>
            <Col span={24} style={{ marginTop: 10 }}>
              <Button
                disabled={!!definitionAction || isUpdatingAction}
                fill
                text="Definition"
                icon="code"
                onClick={() => {
                  setDefinitionAction(schemaAction);
                }}
              />
            </Col>
          </Row>
        </Col>

        <Col span={24} style={{ margin: '10px 0 0 0' }}>
          <Divider />
        </Col>

        {/* Company ID */}
        <Col span={24} className="detailViewSection">
          <Section
            title="Name"
            rightElement={
              <Button
                minimal
                small
                rightIcon={copiedTextToClipboard ? 'tick' : null}
                text={copiedTextToClipboard ? 'Copied' : 'Copy'}
                intent={copiedTextToClipboard ? 'success' : 'primary'}
                onClick={() => copyTextToClipboard(schemaAction?.name)}
              />
            }
          >
            <span>{schemaAction?.name}</span>
          </Section>
        </Col>

        <Col span={24} style={{ margin: '10px 0 0 0' }}>
          <Divider />
        </Col>

        {/* Schema Action Details */}
        <Col span={24} className="detailViewSection">
          <Section
            title="Schema Action Details"
            rightElement={
              <Button
                minimal
                small
                text={isShowingSchemaActionDetails ? 'Hide' : 'Show'}
                intent="primary"
                onClick={(e: any) => {
                  e.stopPropagation();
                  setIsShowingSchemaActionDetails(!isShowingSchemaActionDetails);
                }}
              />
            }
          >
            <Row>
              <Col span={24}>
                <Collapse isOpen={isShowingSchemaActionDetails}>
                  <Row>
                    {/* Type */}
                    <Col span={24} style={{ marginTop: 5 }}>
                      <EntityTitle
                        title="Type"
                        subtitle={<span>{getSchemaActionType(schemaAction) || 'None'}</span>}
                      />
                    </Col>

                    {/* Stages */}
                    <Col span={24} style={{ marginTop: 15 }}>
                      <EntityTitle
                        title="Stages"
                        subtitle={
                          <span>
                            {/* If schemaAction.stages, map them as <Tag> or show '-'*/}
                            {schemaAction?.stages?.length! > 0
                              ? schemaAction?.stages?.map((stage: string, i: number) => (
                                  <Tag
                                    style={{
                                      marginTop: 5,
                                      borderRadius: 4,
                                      marginRight: i === schemaAction?.stages?.length! - 1 ? 0 : 5,
                                    }}
                                    key={stage}
                                    minimal
                                    intent="primary"
                                    fill={false}
                                  >
                                    {stage}
                                  </Tag>
                                ))
                              : 'None'}
                          </span>
                        }
                      />
                    </Col>

                    {/* Target Stages */}
                    <Col span={24} style={{ marginTop: 15 }}>
                      <EntityTitle
                        title="Next Stages"
                        subtitle={
                          <span>
                            {/* If schemaAction.stages, map them as <Tag> or show '-'*/}
                            {schemaAction?.targetStages?.length! > 0
                              ? schemaAction?.targetStages?.map((stage: string, i: number) => (
                                  <Tag
                                    style={{
                                      marginTop: 5,
                                      borderRadius: 4,
                                      marginRight: i === schemaAction?.stages?.length! - 1 ? 0 : 5,
                                    }}
                                    key={stage}
                                    minimal
                                    intent="success"
                                    fill={false}
                                  >
                                    {stage}
                                  </Tag>
                                ))
                              : 'None'}
                          </span>
                        }
                      />
                    </Col>

                    {/* Default Form */}
                    <Col span={12} style={{ marginTop: 15 }}>
                      <EntityTitle
                        title="Default Form"
                        subtitle={
                          <span>
                            {schemaAction.defaultForm ? (
                              <Tag minimal intent="success" style={{ marginTop: 5 }}>
                                YES
                              </Tag>
                            ) : (
                              <Tag minimal intent="danger" style={{ marginTop: 5 }}>
                                NO
                              </Tag>
                            )}
                          </span>
                        }
                      />
                    </Col>

                    {/* User Action */}
                    <Col span={12} style={{ marginTop: 15 }}>
                      <EntityTitle
                        title="User Action"
                        subtitle={
                          <span>
                            {schemaAction.userAction ? (
                              <Tag minimal intent="success" style={{ marginTop: 5 }}>
                                YES
                              </Tag>
                            ) : (
                              <Tag minimal intent="danger" style={{ marginTop: 5 }}>
                                NO
                              </Tag>
                            )}
                          </span>
                        }
                      />
                    </Col>

                    {used.length > 0 && (
                      <Col span={24} style={{ marginTop: 15, marginBottom: 5 }}>
                        <EntityTitle
                          title="Used in Flows"
                          subtitle={
                            <span>
                              {used.map((action) => (
                                <Tag
                                  intent={'primary'}
                                  style={{
                                    marginTop: 5,
                                    borderRadius: 4,
                                    marginRight: 5,
                                    cursor: 'pointer',
                                  }}
                                  onClick={() => setSelectedAction(action)}
                                  key={action.id}
                                  minimal
                                  fill={false}
                                >
                                  {action.name}
                                </Tag>
                              ))}
                            </span>
                          }
                        />
                      </Col>
                    )}

                    {/* Updated At */}
                    <Col span={12} style={{ marginTop: 15 }}>
                      <EntityTitle
                        title="Updated At"
                        subtitle={
                          <span>
                            {dayjs(schemaAction?.updatedAt).format('DD/MM/YYYY HH:mm') || 'None'}
                          </span>
                        }
                      />
                    </Col>

                    {/* Created At */}
                    <Col span={12} style={{ marginTop: 15, marginBottom: 20 }}>
                      <EntityTitle
                        title="Created At"
                        subtitle={
                          <span>
                            {dayjs(schemaAction?.createdAt).format('DD/MM/YYYY HH:mm') || 'None'}
                          </span>
                        }
                      />
                    </Col>
                  </Row>
                </Collapse>
              </Col>
            </Row>
          </Section>
        </Col>

        <Col span={24} style={{ margin: 0 }}>
          <Divider />
        </Col>

        {/* Schema Details */}
        <Col span={24} className="detailViewSection">
          <Section
            title="Schema Details"
            rightElement={
              <Button
                minimal
                small
                text={isShowingSchemaDetails ? 'Hide' : 'Show'}
                intent="primary"
                onClick={(e: any) => {
                  e.stopPropagation();
                  setIsShowingSchemaDetails(!isShowingSchemaDetails);
                }}
              />
            }
          >
            <Row>
              <Col span={24}>
                <Collapse isOpen={isShowingSchemaDetails}>
                  <Row>
                    {/* Schema Type */}
                    <Col span={24} style={{ marginTop: 5 }}>
                      <EntityTitle
                        loading={isLoadingSchema}
                        title="Schema Type"
                        subtitle={<span>{getSchemaType(schemaAction)}</span>}
                      />
                    </Col>

                    {/* Module Name */}
                    <Col span={24} style={{ marginTop: 15 }}>
                      <EntityTitle
                        loading={isLoadingSchema}
                        title="Module Name"
                        subtitle={<span>{schema?.moduleName || '...'}</span>}
                      />
                    </Col>

                    <Col span={24} style={{ marginTop: 15 }}>
                      <EntityTitle
                        loading={isLoadingSchema}
                        title="Entity Name"
                        subtitle={<span>{schema?.entityName || '...'}</span>}
                      />
                    </Col>
                  </Row>
                </Collapse>
              </Col>
            </Row>
          </Section>
        </Col>
      </Row>

      {/* Form */}
      <FormModal formUUID={schemaAction?.id} onSubmitEvent={handleFormSubmit} />

      {/* Delete Action Alert */}
      <Alert
        intent="danger"
        canEscapeKeyCancel
        cancelButtonText="Cancel"
        confirmButtonText="Delete"
        isOpen={isDeleteActionAlertVisible}
        onCancel={() => setIsDeleteActionAlertVisible(false)}
        onClose={() => setIsDeleteActionAlertVisible(false)}
        // onConfirm={deleteCompany}
      >
        <p>Are you sure you want to delete this Action? This action cannot be undone.</p>
      </Alert>
    </>
  );
};

const mapState = (state: any) => ({});

const mapDispatch = (dispatch: any) => ({
  initializeForm: (params: any) => dispatch(initializeSharedForm(params)),
  editUser: (params: EditUser, cb: any) => dispatch(editUserRequest(params, cb)),
  alertMessage: (params: { body: string; type: string }) => dispatch(displayMessage(params)),
});

export default connect(mapState, mapDispatch)(SchemaActionDetails);
